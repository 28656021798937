import "../App.css";
import { useContext, useEffect, useState } from "react";
import Input from "@mui/joy/Input";
import { Textarea } from "@mui/joy";
import Button from "@mui/joy/Button";
import SweetAlert from "./SweetAlert";
import Autocomplete from "@mui/joy/Autocomplete";
import { DataContext } from "../DataContext";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";

function CreateJob() {
  //context
  const { user, internalapi, setView} = useContext(DataContext);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]); // Default value
  const [options, setOptions] = useState(["react", "eosjs", "css"]);
  const [open, setOpen] = useState(false);

  const [formState, setFormState] = useState({
    work_title: "",
    work_description: "",
    total_budget: "",
    requirements: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTagInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const newOptions = [...new Set([...options, ...newValue])];
    setOptions(newOptions);
  };

  const submitJob = async () => {
    const token = await user.getIdToken();
    if(Number(formState?.total_budget) == 0){
      SweetAlert("The budget has to be higher than 0.")
      return
    }
    if(Number(formState?.total_budget) > 100000){
      SweetAlert("We don't currently support jobs with budgets higher than USDT 100,000.")
      return
    }
    if(!formState.work_title || !formState.work_description || !formState.total_budget || !formState.requirements){
      SweetAlert("Please fill in the fields.")
      return
    }

    const response = await fetch(`${internalapi}/createjob`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, ...formState, tags: value}),
    });
    if(response.ok){
      SweetAlert("Job created.")
      setView("Feed")
    }
  };

  useEffect(()=>{
    //fetch tags
    const fetchTags = async () => {
      try {
        const response = await fetch(
          `https://eos.eosusa.io/v1/chain/get_table_rows`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: "jobboard1111",
              scope: "jobboard1111",
              table: "tagid1",
              json: true,
              limit: 1,
            }),
          }
        );
        const data = await response.json();
        let taglist = []
        data?.rows[0]?.tags_and_work_offer_ids.map((tag) => {taglist.push(tag?.key)})
        setOptions(taglist)
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };
    fetchTags();
  }, [])

  const handleAddTag = () => {
    // Check if the inputValue is not empty and not already included in the value array
    if (inputValue && !value.includes(inputValue)) {
      const newValue = [...value, inputValue]; // Add the inputValue to the tags list
      setValue(newValue); // Update the value state with the new list of tags
      // Optionally, update options if you're tracking available tags separately
      if (!options.includes(inputValue)) {
        setOptions([...options, inputValue]);
      }
      setInputValue(''); // Clear the inputValue if you want
    }
  };

  return (
    <div className="createJob">
    <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      >
        <Sheet
          sx={{
            minWidth:"50vw",
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            padding:"20px"
          }}
        >
            <div className="gradientText" style={{fontSize:"18px", fontWeight:"700"}}>Paying in a custom token</div>
            <div style={{color:"#0B6DCC", fontSize:"14px"}}>
            We can accept payments in any token. The process is manual, and you’ll have to contact us at the time of payment.<br/>

            For now, please indicate the budget in USDT. When time for payments comes, we’ll convert other tokens into USDT.
            </div>
            <div style={{marginTop:"20px"}}><a href="https://t.me/softwarefocus" target="_blank" className="applyButton gradientBackground" style={{width:"80px", textAlign:"center", textDecoration:"none"}}>Contact us</a></div>
        </Sheet>
      </Modal>
      <div className="profilePageCard">
        <div className="profilePageCardHeader gradientBackground">
          <div>Create a job</div>
        </div>
        <div className="profilePageCardBody">
          <div className="inputDescriptor">Work title</div>
          <Input
            type="text"
            placeholder="Example: Build a website for JobBoard"
            name="work_title"
            onChange={handleInputChange}
          />

          <div className="inputDescriptor">Work description</div>
          <Textarea
            minRows={2}
            variant="outlined"
            placeholder="Example: I need a website for my company. It should have a landing page, a blog, a contact form, and a login page."
            name="work_description"
            onChange={handleInputChange}
          />

          <div className="inputDescriptor">Total budget</div>
          <Input
            type="number"
            placeholder="1000"
            name="total_budget"
            startDecorator={
              <Button
                variant="soft"
                color="neutral"
                onClick={() => SweetAlert("USDT is token fixed to USD.")}
              >
                USDT
              </Button>
            }
            onChange={handleInputChange}
          />
          <div className="gradientText payCustomTokenButton" onClick={()=>setOpen(true)}>
            🛈 I want to pay in a custom token
          </div>

          <div className="inputDescriptor">Requirements for applicants</div>
          <Textarea
            minRows={2}
            variant="outlined"
            placeholder="Example: 3 years of experience with React. Experience with deploying apps on Antelope. Experience with integrating wallets."
            name="requirements"
            onChange={handleInputChange}
          />

          <div className="inputDescriptor">
            Add tags to help workers find your offer
          </div>
          <Autocomplete
            multiple
            freeSolo
            value={value}
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={handleTagInputChange}
            options={options}
            endDecorator={
              <Button
                variant="soft"
                color="neutral"
                onClick={handleAddTag}
              >
                Add tag
              </Button>
            }
            placeholder="List of tags"
          />

          <div
            onClick={() => submitJob()}
            className="applyButton gradientBackground"
            style={{ marginTop: "10px", marginLeft: "auto" }}
          >
            Submit offer
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateJob;
