import React, { useState, useEffect, useRef } from 'react'
import { DataContext } from "../DataContext";
import { useContext } from "react";
import { set } from 'lodash';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';

export default function Landing(props){
const { view, setView, user } = useContext(DataContext);
const [landingOrgs, setLandingOrgs] = useState([]);
useEffect(() => {
  //fetch landingorgs table
  const fetchLandingOrgs = async () => {
    try {
      const response = await fetch(
        `https://eos.eosusa.io/v1/chain/get_table_rows`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            code: "jobboard1111",
            scope: "jobboard1111",
            table: "landingorgs1",
            json: true,
            limit:1000
          }),
        }
      );
      const data = await response.json();
      setLandingOrgs(data?.rows);
      console.log(data)
    } catch (error) {
      console.error("Error fetching landingorgs:", error);
    }
  };
  fetchLandingOrgs();
}, []);

  return(
    <>
    <div className="landing">
    <div className="landingNav"><a className="landingSocialLink" href="https://t.me/+7jd7fJbalRE2MGI0" target="_blank">Telegram</a> | <a className="landingSocialLink" href="https://x.com/Job_Board_" target="_blank">Twitter</a> | <a className="landingSocialLink" href="https://discord.gg/wMmmMW8C" target="_blank">Discord</a></div>
    <div className="background">
      <div className="gradient-shape shape1"></div>
      <div className="gradient-shape shape2"></div>
      <div className="gradient-shape shape3"></div>

    </div>
    <div className="landingLeft">
        <div className="landingTitle">JobBoard</div>
        <div className="landingDescription">JobBoard is a platform that connects job seekers with employers.</div>
        <button className="applyButton gradientBackground getStartedButton" onClick={() => setView("Feed")}>Get started</button>
    </div>
    <div className="landingRight">
    <iframe className="demo" src="https://www.youtube.com/embed/cmqqVNBOWVk" title="Placeholder Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    </div>
    <div className="accordionWrapper">
    <div className="accordionContainer">
    
    <div className="sponsorsTitle" style={{marginTop:"30px"}}>FAQ</div>

    <AccordionGroup sx={{ maxWidth: "100%" }}>
      <Accordion>
        <AccordionSummary>Registration </AccordionSummary>
        <AccordionDetails>
        <p>To use the Job Board, click Get Started (located next to "Voting & Login" at the top of the main page). Choose either Job Seeker or  Employer – you cannot select both with a single account.</p>
        <img src="faqimg.png" style={{maxWidth:"350px"}} />
        <b>Looking to Hire (post a Task / Job)</b>
        <ul>
        <li>First tick that you have read and agree with the Terms & Conditions</li>
        <li>Click Login, a pop-up box will ask you to sign in with your standard Gmail account</li>
        <li>Once logged in refresh your page using F5, then Update your profile, View current jobs, Create a job, Deposit funds, Vote on certain topics (if available to you) </li>
        </ul>
        
        <b>Looking for Work (offering your services to get paid completing jobs)</b>
        <ul>
        <li>First tick that you have read and agree with the Terms & Conditions</li>
        <li>Click Login, a pop up box will ask you to sign in with your standard Gmail account</li>
        <li> Once logged in refresh your page using F5, then Update your profile, View current jobs, apply for any available job, Vote on certain topics (if available to you)</li>
        </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Post Job</AccordionSummary>
        <AccordionDetails>
        <p>To post a job click <b>Create Job</b> and fill in all required fields, including budget and tags, to help prospective workers find the job easily. Your job will then appear on the Job Board. Applications for the job will be shown in the <b>My Work</b> section, where you can review and approve or reject them as needed.</p>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Apply for Job</AccordionSummary>
        <AccordionDetails>
        <p>To apply for a job, first find a suitable position using the <b>Pay range</b> filter on the left side of the main page. You can also use the <b>tag</b> option to search for specific job types or criteria, such as Smart Contracts, and <b>Sort by</b> options like Newest first.</p>
        <p>Open any job you feel may be suitable and read the job description. If you believe you are a good fit, fill in the required fields with details of your suitability and job offer, which can be broken down into multiple steps or milestones. If your application is successful and approved, it will appear under <b>My Work</b>. You can filter jobs at various stages, such as Pending, Accepted, or Rejected.</p>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Payment</AccordionSummary>
        <AccordionDetails>
        <p>As a <b>worker</b>, you will receive agreed-upon crypto payments only after each milestone is successfully completed. These payments will be sent directly to the crypto account specified during registration.</p>

        <p>As a <b>hirer</b>, you must deposit sufficient funds (preferably EOS) for the jobs you have posted, covering each milestone. Ensure you fill in the memo when sending funds as shown below.</p>
        <p><b>Disclaimer: Job Board hereby disclaims any liability for non-payment or issues arising from completed work, in accordance with the Terms & Conditions.</b></p>

        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Vote</AccordionSummary>
        <AccordionDetails>
        Voting is restricted to whitelisted members of registered organizations. To become a whitelisted member, please contact the associated organization authorized to conduct polls on Job Board."
        </AccordionDetails>
      </Accordion>
    </AccordionGroup>
    </div>
    </div>
    <div className="sponsorsTitle" style={{marginTop:"30px"}}>Sponsors</div>
        <div className="landingCards">
          {landingOrgs.map((org) => {
            return(
                <div className="landingCard">
                    <img src={org?.logo_image} className="landingCardLogo" width="72px" height="72px" style={{borderRadius:"5px"}} />
                    <div className="landingCardText">
                      <div className="landingCardHeader">{org?.name}</div>
                      <div className="landingCardBody">{org?.description}</div>
                    </div>
                </div>
            )
          })}
          
    </div>

    </>
  )
}