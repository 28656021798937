import "../App.css";
import { DataContext } from "../DataContext";
import { useContext, useEffect, useState } from "react";
import Modal from '@mui/joy/Modal';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';

function PaymentHistory({account, type}) {
    const { user, chainaccount } = useContext(DataContext);
    const [paymentHistoryopen, setPaymentHistoryOpen] = useState(false);
    const [paymentHistory, setPaymentHistory] = useState([])
    

    useEffect(() => {
        const fetchHistory = async () => {
            if(type === "worker"){
                try {
                const response = await fetch(
                    `https://eos.eosusa.io/v1/chain/get_table_rows`,
                    {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        code: "jobboard1111",
                        scope: "jobboard1111",
                        table: "paymnthist1",
                        key_type:"name",
                        index_position:3,
                        lower_bound: account,
                        upper_bound: account,
                        json: true,
                        limit: 1000,
                    }),
                    }
                );
        
                const data = await response.json();
                setPaymentHistory(data?.rows)

                } catch (error) {
                console.error("Error fetching communities:", error);
                }
            }
            else if (type === "workprovider"){
                try {
                    const response = await fetch(
                    `https://eos.eosusa.io/v1/chain/get_table_rows`,
                    {
                        method: "POST",
                        headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                        code: "jobboard1111",
                        scope: "jobboard1111",
                        table: "paymnthist1",
                        key_type:"name",
                        index_position:2,
                        lower_bound: account,
                        upper_bound: account,
                        json: true,
                        limit: 1000,
                        }),
                    }
                    );
            
                    const data = await response.json();
                    setPaymentHistory(data?.rows)
    
                } catch (error) {
                    console.error("Error fetching communities:", error);
                }
            }
        }
        if(account && type && paymentHistoryopen){
        fetchHistory();
        }
    }, [account, type, paymentHistoryopen])
    return(
        <>
        <div className="seePaymentsButton" onClick={()=>{
            setPaymentHistoryOpen(true)
        }
            }>See payments</div>
        <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={paymentHistoryopen}
        onClose={() => setPaymentHistoryOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            padding:"20px",
            maxHeight:"80vh",
            maxWidth:"80vw",
            overflow:"auto"
          }}
        >
            <div className="gradientText" style={{fontSize:"20px", fontWeight:"700"}}>Payment History</div>
            <Table>
                <thead>
                    <tr>
                        <th>Amount</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Time</th>
                    </tr>
                </thead>
                <tbody>
                    {paymentHistory?.map((payment, index) => {
                        return(
                            <tr key={index}>
                                <td>{payment?.amount}</td>
                                <td>{payment?.org_account}</td>
                                <td>{payment?.worker_account}</td>
                                <td>{payment?.payment_time.slice(0, 10)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            {paymentHistory?.length === 0 && <div style={{width:"100%", textAlign:"center", marginTop:"10px"}}><i>No payments made</i></div>}

        </Sheet>
      </Modal>
      </>
    )
}

export default PaymentHistory;