import "../App.css";
import { useState, useEffect } from "react";
//contexxt and usecontext
import { DataContext } from "../DataContext";
import { useContext } from "react";
import { Modal } from "@mui/joy";
import { Sheet } from "@mui/joy";
import MakeDeposit from "./MakeDeposit";
import SweetAlert from "./SweetAlert";
import cloneDeep from 'lodash/cloneDeep';


function WorkProviderWork() {
  const { user, workers, chainaccount, internalapi, setCurrentProfile, setView, setEditCurrentJob} = useContext(DataContext);
  const [workOffers, setWorkOffers] = useState([]);
  const [displayWorkOffers, setDisplayWorkOffers] = useState([]);
  const [currentWorkOffer, setCurrentWorkOffer] = useState(null);
  const [workFilter, setWorkfilter] = useState("All");
  const [open, setOpen] = useState(false);
  const [milestoneIndexModal, setMilestoneIndexModal] = useState();
  const [makedepositopen, setMakeDepositOpen] = useState(false);
  const [refetchworkoffers, setRefetchWorkOffers] = useState(0);
  const [workDetails, setWorkDetails] = useState(false);
  const [currentWorkDetails, setCurrentWorkDetails] = useState(null);

  useEffect(() => {
    if (workFilter === "All") {
      setDisplayWorkOffers(workOffers);
    } else if (workFilter === "Proposed") {
      //get all work offers where any application in applications has application_status == 0
      setDisplayWorkOffers(
        workOffers.filter((workOffer) =>
          workOffer.applications.some(
            (application) => application.application_status === 0
          )
        )
      );
    } else if (workFilter === "Ongoing") {
      //get all work offers where any application in applications has application_status == 1
      setDisplayWorkOffers(
        workOffers.filter((workOffer) =>
          workOffer.applications.some(
            (application) => application.application_status === 1
          )
        )
      );
    } else if (workFilter === "Finished") {
      //get all work offers where any application in applications has application_status == 3
      setDisplayWorkOffers(
        workOffers.filter((workOffer) =>
          workOffer.applications.some(
            (application) => application.application_status === 3
          )
        )
      );
    }
  }, [workFilter, workOffers]);

  const payformilestone = async (work_application_id, milestone_nr) => {
    const token = await user.getIdToken();
    const response = await fetch(`${internalapi}/payformilestone`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        work_application_id: work_application_id,
        milestone_nr: milestone_nr,
      }),
    });

    if(response.ok){
      SweetAlert("Payment made.")
      setTimeout(() => {
        setRefetchWorkOffers(prevrefetch => prevrefetch + 1);
      }, 3000);
    }
    else{
      SweetAlert("Error making payment.")
    }
  };

  const sendToEscrow = async (work_application_id, milestone_nr) => {
    const token = await user.getIdToken();
    const res = await fetch(`${internalapi}/sendtoescrow`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        work_application_id: work_application_id,
        milestone_nr: milestone_nr,
      }),
    });
    if (res?.status === 500) {
      SweetAlert("Not enough funds deposited.")
      setMakeDepositOpen(true);
    }
    if(res.ok){
      SweetAlert("Payment sent to escrow.")
      setTimeout(() => {
        setRefetchWorkOffers(prevrefetch => prevrefetch + 1);
      }, 3000);
    }
  }

  const getApplicationStatus = (application, index) => {
    //FOR WORK PROVIDER
    {/*EXAMPLE of APPLICATION:
    {
      "payment_status": [
          0,
          0
      ],
      "milestone_submitted": [
          0,
          0
      ]
      "application_status": 0
    } */}

    let statusMessage;

    switch (application?.application_status) {
      case 0:
        // If application_status is 0 / if first milestone / if last milestone is 1 etc.
        statusMessage = ""
        break;
      case 1:
        // Additional logic for when application_status is 1
        // application ongoing, has been accepted
        if (application?.milestone_submitted[index] == 0) {
          statusMessage = "Awaiting milestone submission";
          if(application?.payment_status[index] == 0){
            statusMessage = statusMessage +  ", payment not in escrow"
          }
          else if(application?.payment_status[index] == 1){
            statusMessage = statusMessage +  ", payment in escrow"
          }
        }
        else if (application?.milestone_submitted[index] == 1) {
          statusMessage = "Need to review milestone";
          if(application?.payment_status[index] == 0){
            statusMessage = statusMessage +  ", payment not in escrow"
          }
          else if(application?.payment_status[index] == 1){
            statusMessage = statusMessage +  ", payment in escrow"
          }
          else if(application?.payment_status[index] == 2){
            statusMessage = "Milestone finished, payment made"
          }
        }
        break;
      case 2:
        statusMessage = "Application rejected"
        break;
      case 3:
        // Additional logic for when application_status is 3
        statusMessage = "Milestone finished, payment made";
        break;
      default:
        statusMessage = "";
        break;
    }
    return statusMessage
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const getApplicationButtons = (application) => {
    //FOR WORK PROVIDER
    {/*EXAMPLE of APPLICATION:
    {
      "payment_status": [
          0,
          0
      ],
      "milestone_submitted": [
          0,
          0
      ]
      "application_status": 0
    } */}

    let buttons;

    switch (application?.application_status) {
      case 0:
        // If application_status is 0 / if first milestone / if last milestone is 1 etc.
        buttons = <><button className="gradientBackground applyButton" onClick={()=>acceptrejectapplication(application, true)}>Accept application</button> <button className="gradientBackground applyButton" onClick={()=>acceptrejectapplication(application, false)}>Reject application</button></>
        break;
      case 1:
        break;
      case 2:
        buttons = ""
        break;
      case 3:
        // Additional logic for when application_status is 3
        buttons = ""
        break;
      default:
        buttons = ""
        break;
    }

    return buttons
  }


  const acceptrejectapplication = async (application, acceptreject) => {
    const token = await user.getIdToken();
    const response = await fetch(`${internalapi}/acceptrejectapplication`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        work_offer_id: application.offer_id,
        work_application_id: application.work_application_id,
        acceptreject: acceptreject,
      }),
    });
    if(response.ok){
      SweetAlert("Success.");
      setTimeout(() => {
        setRefetchWorkOffers(prevrefetch => prevrefetch + 1);
      }, 2000);
    }
    else{
      SweetAlert("Something went wrong.")
    }

  };

  const getMilestoneButtons = (application, index) => {
    //FOR WORK PROVIDER
    {/*EXAMPLE of APPLICATION:
    {
      "payment_status": [
          0,
          0
      ],
      "milestone_submitted": [
          0,
          0
      ]
      "application_status": 0
    } */}

    let buttons;

    switch (application?.application_status) {
      case 0:
        // If application_status is 0 / if first milestone / if last milestone is 1 etc.
        buttons = ""
        break;
      case 1:
        // Additional logic for when application_status is 1
        // application ongoing, has been accepted
        if (application?.payment_status[index] == 0) {
          buttons = <button onClick={() => sendToEscrow(
            application.work_application_id,
            index
          )}
          className="gradientBackground applyButton"
          >Send payment to escrow</button>
        }
        else if (application?.payment_status[index] == 1 && application?.milestone_submitted[index] == 1) {
          buttons = <button
          onClick={() => payformilestone(application.work_application_id, index)}
          className="gradientBackground applyButton"
          >Approve milestone and release payment</button>
        }
        break;
      case 2:
        buttons = ""
        break;
      case 3:
        // Additional logic for when application_status is 3
        buttons = ""
        break;
      default:
        buttons = ""
        break;
    }

    return buttons
  
  }

  const endWorkProposal = (id) => {
    const endWorkProposal = async () => {
      const token = await user.getIdToken();
      const response = await fetch(`${internalapi}/endworkproposal`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          offer_id: id,
        }),
      });
      if(response.ok){
        SweetAlert("Work proposal ended.");
        setWorkDetails(false);
        setTimeout(() => {
          setRefetchWorkOffers(prevrefetch => prevrefetch + 1);
        }, 2000);
      }
      else{
        SweetAlert("Error ending work proposal.")
      }

    };
    endWorkProposal();
  }
    
  useEffect(() => {
    const fetchWorkOffers = async () => {
      if (!chainaccount) return;
  
      try {
        // Fetch applications
        const appResponse = await fetch(`https://eos.eosusa.io/v1/chain/get_table_rows`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            code: "jobboard1111",
            scope: "jobboard1111",
            table: "workaplictn3",
            json: true,
            limit: 1000,
          }),
        });
        const appData = await appResponse.json();
        const applications = appData?.rows;
  
        // Fetch work offers
        const offersResponse = await fetch(`https://eos.eosusa.io/v1/chain/get_table_rows`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            code: "jobboard1111",
            scope: "jobboard1111",
            table: "workoffers2",
            index_position: 2,
            key_type: "name",
            lower_bound: chainaccount,
            upper_bound: chainaccount,
            json: true,
            limit: 1000,
          }),
        });
        const offersData = await offersResponse.json();
  
        // Combine and create new objects for work offers and applications
        const combinedData = offersData?.rows.map(offer => ({
          ...offer,
          applications: applications.filter(app => app.offer_id === offer.offer_id).map(app => ({
            ...app
          }))
        }));
      // Use cloneDeep to deep clone combinedData before updating state
      const clonedData = cloneDeep(combinedData);
      setWorkOffers(clonedData);
      setDisplayWorkOffers(clonedData);
      if(currentWorkOffer){
        setCurrentWorkOffer(clonedData.find(offer => offer.offer_id === currentWorkOffer.offer_id));
      }
      } catch (error) {
        console.error("Error fetching work offers", error);
      }
    };
  
    fetchWorkOffers();
  }, [chainaccount, refetchworkoffers]); // Dependencies
  
  return (
    <div className="workFeed">
      <div className="workFeedLeft">
        <div className="workFeedFilterCard">
          <div className="workFeedFilterCardHeader gradientBackground">
            <div className="areaDescriptor">Filter by work stage</div>
            <div className="workFeedFilterCardHeaderButtons">
              <div
                style={{ borderRadius: "10px 0px 0px 10px" }}
                className={`workFeedFilter ${
                  workFilter === "Proposed" ? "workFeedFilterSelected" : ""
                }`}
                onClick={() => setWorkfilter("Proposed")}
              >
                {" "}
                <img src="/card.svg" /> Proposed
              </div>
              <div
                className={`workFeedFilter ${
                  workFilter === "Ongoing" ? "workFeedFilterSelected" : ""
                }`}
                onClick={() => setWorkfilter("Ongoing")}
              >
                <img src="/handshake.svg" /> Ongoing
              </div>
              <div
                style={{ borderRadius: "0px 10px 10px 0px" }}
                className={`workFeedFilter ${
                  workFilter === "Finished" ? "workFeedFilterSelected" : ""
                }`}
                onClick={() => setWorkfilter("Finished")}
              >
                <img src="/checkmark.svg" /> Finished
              </div>
            </div>
            <div
              className="resetFiltersWorkFeed"
              style={{ width: "80px" }}
              onClick={() => setWorkfilter("All")}
            >
              See all work
            </div>
          </div>
          <div className="workFeedFilterWorkCards">
            { displayWorkOffers?.length > 0 ? displayWorkOffers?.map((workOffer, index) => {
              return (
                <div className="workFeedFilterWorkCard" style={{color: workOffer?.offer_id == currentWorkOffer?.offer_id ? "white" : "#0B6BCB", background: workOffer?.offer_id == currentWorkOffer?.offer_id ? "linear-gradient(135deg, #0B6BCB, #00D1FF)" : "white"}} onClick={() => setCurrentWorkOffer(workOffer)}>
                  <div className="workFeedFilterWorkCardTitle">
                    {workOffer?.work_title}
                  </div>
                  <div
                    className="workFeedFilterDetails gradientBackground"
                    onClick={() => {
                      setCurrentWorkDetails(workOffer);
                      setWorkDetails(true)
                    }}
                  >
                    View details
                  </div>
                </div>
              );
            })
            :
            <div className="workFeedFilterWorkCard" style={{fontSize:"14px", fontWeight:"700"}}>
            No work with these filters
            </div>
          }
          </div>
        </div>
      </div>
      <div className="workFeedRight">
        {currentWorkOffer ? (
          currentWorkOffer.applications.length === 0 ?
          <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>No applications for this work offer.</div>
          :
          <>
            {currentWorkOffer.applications.map((application, applicationindex) => (
              <div key={application.id} className="workFeedMainCard">
                {" "}
                {/* Assuming each application has a unique 'id' */}
                <div className="workFeedJobCardTitle gradientText">
                  {currentWorkOffer.work_title ? currentWorkOffer.work_title : "No title"}
                </div>
                <div style={{fontSize:"14px"}}>Application submitted on: {formatDate(application?.creation_time)}</div>

                <div className="workProviderWorkWorkerInfo">
                <img
                  src={workers?.find(worker => worker?.worker === application?.worker)?.profile_pic}
                  height="32px"
                  width="32px"
                  alt="Profile"

                  style={{borderRadius:"50%", cursor:"pointer"}}
                  onError={(e) => e.currentTarget.src ='/no_provider_profile.jpg'}
                  onClick={() =>{
                    setCurrentProfile(application?.worker)
                    setView("WorkerProfile")
                  }
                  }
                />
                <div 
                                  onClick={() =>{
                                    setCurrentProfile(application?.worker)
                                    setView("WorkerProfile")
                                  }}
                style={{fontSize:"14px", fontWeight:"700", cursor:"pointer"}}>{workers?.find(worker => worker?.worker === application?.worker)?.username}</div>
                </div>
                {application?.milestone_name?.map((milestone, index) => (
                  <div className="workFeedMilestoneCard">
                    <div className="workFeedMilestoneCardHeader">
                      <div>
                        <div className="milestoneNumber gradientText">
                          Milestone {index + 1}
                        </div>
                        <div>
                          Payment: {application?.payment_per_milestone[index]}
                        </div>
                      </div>
                      <div 
                          key={application?.application_status + "-" + application?.payment_status[index]}
                          className="milestoneStatus" 
                          style={{ display: 
                            application?.application_status === 0 ? "none" :
                            application?.application_status === 1 ? (
                              application?.milestone_submitted[index] === 0 ? (
                                application?.payment_status[index] === 0 ? "Awaiting milestone submission, payment not in escrow" : 
                                application?.payment_status[index] === 1 ? "Awaiting milestone submission, payment in escrow" : ""
                              ) : application?.milestone_submitted[index] === 1 ? (
                                application?.payment_status[index] === 0 ? "Need to review milestone, payment not in escrow" : 
                                application?.payment_status[index] === 1 ? "Need to review milestone, payment in escrow" : 
                                application?.payment_status[index] === 2 ? "Milestone finished, payment made" : ""
                              ) : ""
                            ) : application?.application_status === 2 ? "Application rejected" :
                            application?.application_status === 3 ? "Milestone finished, payment made" : "none"
                          }}
                        >
                          {
                            application?.application_status === 0 ? "" :
                            application?.application_status === 1 ? (
                              application?.milestone_submitted[index] === 0 ? (
                                application?.payment_status[index] === 0 ? "Awaiting milestone submission, payment not in escrow" : 
                                application?.payment_status[index] === 1 ? "Awaiting milestone submission, payment in escrow" : ""
                              ) : application?.milestone_submitted[index] === 1 ? (
                                application?.payment_status[index] === 0 ? "Need to review milestone, payment not in escrow" : 
                                application?.payment_status[index] === 1 ? "Need to review milestone, payment in escrow" : 
                                application?.payment_status[index] === 2 ? "Milestone finished, payment made" : ""
                              ) : ""
                            ) : application?.application_status === 2 ? "Application rejected" :
                            application?.application_status === 3 ? "Milestone finished, payment made" : ""
                          }
                        </div>

                    </div>
                    <div className="workFeedMilestoneTitle">{milestone}</div>
                    <div className="workFeedMilestoneDescription">
                      {application?.milestone_description[index]}
                    </div>
                    {application?.milestone_submission_comment[index] ?
                    <div className="workFeedMilestoneSubmissionComment">
                      <div style={{fontWeight:"700"}}>Submission comment</div> 
                      <div>{application?.milestone_submission_comment[index]}</div>
                      </div>
                      : null
                    }
                    
                    <div className="milestoneButtons" key={application?.application_status?.toString() + application?.payment_status?.toString()}>
  {
    application?.application_status === 0 ? null :
    application?.application_status === 1 ? (
      application?.payment_status[index] === 0 ? (
        <button 
          data-key={`sendToEscrow-${application.work_application_id}-${index}-${application.payment_status[index]}`}
          onClick={() => sendToEscrow(application.work_application_id, index)}
          className="gradientBackground applyButton"
        >
          Send payment to escrow
        </button>
      ) : application?.payment_status[index] === 1 && application?.milestone_submitted[index] === 1 ? (
        <button 
          data-key={`payForMilestone-${application.work_application_id}-${index}-${application.payment_status[index]}-${application.milestone_submitted[index]}`}
          onClick={() => payformilestone(application.work_application_id, index)}
          className="gradientBackground applyButton"
        >
          Approve milestone and release payment
        </button>
      ) : null
    ) : application?.application_status === 2 ? null :
    application?.application_status === 3 ? null : null
  }
</div>

                  </div>
                ))}
                <div key={application?.application_status} style={{marginTop:"20px", display:"flex", gap:"10px"}}>{getApplicationButtons(application)}</div>
              </div>
            ))}
          </>
        )
        :
        <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>Select a work offer to view applications.</div>
      }
      </div>


      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      >
        <Sheet
          sx={{
            minWidth:"50vw",
            maxWidth:"90vw",

            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            padding:"20px"
          }}
        >
            <div className="gradientText" style={{fontSize:"18px", fontWeight:"700"}}>{currentWorkOffer?.applications[milestoneIndexModal?.applicationIndex]?.milestone_name[milestoneIndexModal?.milestoneIndex]}</div>
            <div>Payment: {currentWorkOffer?.applications[milestoneIndexModal?.applicationIndex]?.payment_per_milestone[milestoneIndexModal?.milestoneIndex]}</div>
            <div>Description: {currentWorkOffer?.applications[milestoneIndexModal?.applicationIndex]?.milestone_description[milestoneIndexModal?.milestoneIndex]}</div>
            <div>Submission comment: {currentWorkOffer?.applications[milestoneIndexModal?.applicationIndex]?.milestone_submission_comment[milestoneIndexModal?.milestoneIndex]}</div>

            <div style={{marginTop:"20px", width:"130px", textAlign:"center"}} className="applyButton gradientBackground">View worker profile</div>
        </Sheet>
      </Modal>
      <MakeDeposit makedepositopen={makedepositopen} setMakeDepositOpen={setMakeDepositOpen}/>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={workDetails}
        onClose={() => setWorkDetails(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      >
        <Sheet
          sx={{
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            padding:"20px",
            minWidth:"50vw",
            maxWidth:"90vw",

          }}
        >
            <div className="gradientText" style={{fontSize:"18px", fontWeight:"700", wordWrap:"break-word"}}>{currentWorkDetails?.work_title}</div>
            <div style={{color:"#0B6DCC", fontSize:"16px", fontWeight:"700"}}>Budget: {currentWorkDetails?.total_budget}</div>
            <div style={{color:"#0B6DCC", fontSize:"16px"}}>Work offer is {currentWorkDetails?.active === 1 ? <b>active</b> : <b>not active.</b>}</div>
            <div className="gradientText" style={{fontSize:"16px", fontWeight:"700", marginTop:"10px", wordWrap:"break-word"}}>Description</div>
            <div style={{color:"#0B6DCC", fontSize:"16px"}}>{currentWorkDetails?.work_description}</div>
            <div className="gradientText" style={{fontSize:"16px", fontWeight:"700", marginTop:"10px"}}>Requirements for applicant</div>
            <div style={{color:"#0B6DCC", fontSize:"16px", wordWrap:"break-word"}}>{currentWorkDetails?.requirement}</div>
            <div className="gradientText" style={{fontSize:"16px", fontWeight:"700", marginTop:"10px"}}>Tags</div>
            <div className="jobTags" style={{marginTop:"5px", flexWrap:"wrap", maxWidth:"100%"}}>
                {currentWorkDetails?.tags.map((tag) => (
                  <span className="jobTag">{tag}</span>
                ))}
            </div>
            <div className="workProposalDetailsButtons" style={{marginTop:"20px"}}>
              <button className="gradientBackground applyButton" onClick={() => {
                setEditCurrentJob(currentWorkDetails)
                setView("EditJob")
              }}>Edit work proposal</button>
              <button className="gradientBackground applyButton" onClick={() => endWorkProposal(currentWorkDetails?.offer_id)}>End work proposal</button>
            </div>
        </Sheet>
      </Modal>

      
    </div>
  );
}

export default WorkProviderWork;
