import "../App.css";
import { DataContext } from "../DataContext";
import { useContext, useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Modal from '@mui/joy/Modal';
import Sheet from '@mui/joy/Sheet';
import Textarea from '@mui/joy/Textarea';
import Input from "@mui/joy/Input";
import { withUAL } from "ual-reactjs-renderer";
import SweetAlert from "./SweetAlert";


function Voting(props) {
  const {
    ual: { activeUser, showModal, logout }
  } = props;

  
  const [accountName, setAccountName] = useState("");
  const [communities, setCommunities] = useState([]);
  const [pollingcommunity, setPollingcommunity] = useState("");
  const [pollsloading, setPollsLoading] = useState(true)
  const [refetch, setRefetch] = useState(0)
  const [polls, setPolls] = useState([]);
  const [formState, setFormState] = useState({
    question: "",
    description: "",
    expiration: "",
  });

  function calculateTimeLeft(creationTime, expirationInSeconds) {
    const creationDate = new Date(creationTime + 'Z'); // Adding 'Z' to indicate UTC
    const expirationDate = new Date(creationDate.getTime() + expirationInSeconds * 1000);
    const now = new Date();
    const timeLeft = expirationDate - now;
  
    // Check if the time left is below 0 and return "Poll has expired"
    if (timeLeft <= 0) {
      return "Poll has expired";
    }
  
    let days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    let hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  
    if (days > 0) {
      return `Expires in ${days} days ${hours} hours`;
    } else {
      return `Expires in ${hours} hours ${minutes} minutes`;
    }
  }
  

  useEffect(() => {
    if (activeUser) {
      console.log(activeUser)
      activeUser.getAccountName().then((result) => {
        console.log(result)
        setAccountName(result);
      });
    }
    // Cleanup timeout if the component unmounts
  }, [activeUser]);

  useEffect(() => {
        const fetchPolls = async () => {
          try {
            const response = await fetch(`https://eos.eosusa.io/v1/chain/get_table_rows`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    code: "jobboard1111",
                    scope: pollingcommunity,
                    table: "polls1",
                    json: true,
                    limit:1000
                }),
            });
    
            const data = await response.json();
            console.log(data)
            setPolls(data?.rows.reverse())
            setTimeout(() => setPollsLoading(false), 200)
        }
        catch (error) {
            console.error("Error fetching communities:", error);
        }
      }
      fetchPolls()
  }, [pollingcommunity, refetch])

  const vote = async (poll_key, vote) =>{
    if(!activeUser){
      SweetAlert("Please sign in with your EOS account to vote");
      return
    }
    try{
      const transaction = {
        actions: [
          {
            account: "jobboard1111",
            name: "vote",
            authorization: [
              {
                actor: accountName , // use account that was logged in
                permission: "active",
              },
            ],
            data: {
              poll_key: poll_key,
              vote: vote,
              voter: accountName,
              org_account: pollingcommunity,
            },
          },
        ],
      };
      await activeUser.signTransaction(transaction, {
        expireSeconds: 300,
        blocksBehind: 3,
        broadcast: true,
      });
      setTimeout(() => setRefetch(prevRefetch => prevRefetch + 1), 2000)
      SweetAlert("Voted successfully.");
    }
    catch (error) {
      console.error("Error voting:", error);
      SweetAlert("Error voting.");
    }
  }

  useEffect(()=>{
    //fetch communities from orgprofil table
    const fetchCommunities = async () => {
      try {
        const response = await fetch(`https://eos.eosusa.io/v1/chain/get_table_rows`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                code: "jobboard1111",
                scope: "jobboard1111",
                table: "orgprofil1",
                json: true,
                limit:1000
            }),
        });

        const data = await response.json();
        console.log(data)
        setCommunities(data.rows);
        setPollingcommunity(data.rows[0]?.org_account)
    }
    catch (error) {
        console.error("Error fetching communities:", error);
    }
  }
  fetchCommunities()
  },[])

  const logoutUser = async () => {
    try {
      await logout();
      setAccountName(""); // Clear the account name after logout
      } catch (error) {
      console.error("Logout error", error.message);
    }
  };

  const { view, setView, user, internalapi } = useContext(DataContext);
  const [open, setOpen] = useState(false);

  const createPoll = async () => {
    const token = await user.getIdToken();
    if(!formState.question || !formState.description || !formState.expiration){
      SweetAlert("Please fill in all fields");
      return
    }

    if(formState?.expiration <= 0){
      SweetAlert("Expiration has to be higher than 0");
      return
    }

    const response = await fetch(`${internalapi}/createPoll`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...formState, community: pollingcommunity, token: token}),
    });

    if (response.ok) {
      setOpen(false);
      SweetAlert("Poll created successfully");
      setTimeout(() => {
        setRefetch(prevRefetch => prevRefetch + 1);
      }, 2000)
    } else {
      console.error("Error creating poll:", response.statusText);
    }
  };
  

  return (
    <div className="voting">
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth:"50vw",
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            padding:"20px"
          }}
        >
          <div className="gradientText" style={{fontSize:"24px", fontWeight:"600"}}>
            Create poll
          </div>
          <Textarea sx={{marginTop:"20px"}} placeholder="Poll question" minRows={1} 
            onChange={(e) => setFormState({ ...formState, question: e.target.value })}
          />
          <Textarea sx={{marginTop:"20px"}} placeholder="Poll description" minRows={4}
            onChange={(e) => setFormState({ ...formState, description: e.target.value })}
          />
          <Input sx={{marginTop:"20px"}} placeholder="Poll expiration in days" type="number"
            onChange={(e) => setFormState({ ...formState, expiration: e.target.value })}
          />
          <button className="applyButton gradientBackground" style={{marginTop:"20px", width:"100%"}} onClick={() => createPoll()}>Submit</button>
        </Sheet>
      </Modal>

        <div className="votingMenu">
        <button onClick={()=> {
              if(!user){
                SweetAlert("Please sign in with your Gmail account to create a poll");
                return
              }
          setOpen(true)
          }} className="applyButton gradientBackground" style={{marginBottom:"10px", float:"right"}}>Create new poll</button>
        {accountName ?
        <button onClick={()=>logoutUser()} className="applyButton gradientBackground" style={{marginRight:"10px", marginBottom:"10px", float:"right"}}>Log out ({accountName})</button> :
        <button onClick={()=>showModal()} className="applyButton gradientBackground" style={{marginRight:"10px", marginBottom:"10px", float:"right"}}>Sign in</button>
        }
        <Select
          value={pollingcommunity}
          defaultValue={communities?.length > 0 ? communities[0]?.org_account : ""}
          onChange={(e) => {
            console.log(e.target.value)
            setPollingcommunity(e.target.value)}}
          sx={{
            background: "white",
            width: "100%",
            boxSizing: "border-box",
            borderRadius: "10px",
            fontSize: "16px",
            fontFamily: "inherit",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none"
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none"
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
              outline: "none"
            },
            "&.Mui-focused": {
              outline: "none"
            },
            "&:active": {
              outline: "none"
            },
          }}
        >
          {communities.map((community) => (
            <MenuItem value={community?.org_account}>{community?.org_name}</MenuItem>

          ))}
        </Select>
        </div>
        {polls?.length > 0 ?
        <>
        {polls.map((poll) => (
          <div className="pollCard" key={poll?.creation_time}>
            <div
              className="gradientText"
              style={{ fontSize: "18px", fontWeight: "700", wordWrap: "break-word"}}
            >
              {poll.question}
            </div>
            <div className="pollDescription">{poll.description}</div>
            <div className="pollAnswers">
              <div className="pollAnswer">
                <div className="pollAnswerText">Yes ({poll?.yes == 0 ? "0%" : (poll?.yes / (poll?.yes + poll?.no) * 100).toFixed(0) + "%"})</div>
                <LinearProgress
                  sx={{
                    height: "10px", // Make the progress bar 10px thick
                    "& .MuiLinearProgress-bar": {
                      // Assuming targeting works similar to MUI Core
                      backgroundImage: "linear-gradient(90deg, #0B6BCB, #00D1FF)", // Gradient fill
                    },
                    borderRadius: "10px",
                    background: "#E2E2E2",
                  }}
                  variant="determinate"
                  key={poll?.yes + poll?.no}
                  value={ poll?.yes > 0 ? (poll?.yes / (poll?.yes + poll?.no) * 100) : 0}
                />
              </div>
              <div className="pollAnswer">
                <div className="pollAnswerText">No ({poll?.no == 0 ? "0%" : (poll?.no / (poll?.yes + poll?.no) * 100).toFixed(0) + "%"})</div>
                <LinearProgress
                  sx={{
                    height: "10px", // Make the progress bar 10px thick
                    "& .MuiLinearProgress-bar": {
                      // Assuming targeting works similar to MUI Core
                      backgroundImage: "linear-gradient(90deg, #034282, #00D1FF)", // Gradient fill
                    },
                    borderRadius: "10px",
                    background: "#E2E2E2",
                  }}
                  variant="determinate"
                  value={ poll?.no > 0 ? (poll?.no / (poll?.yes + poll?.no) * 100) : 0}
                />
              </div>
            </div>
            <div style={{fontSize:"13px", color:"gray"}}>{calculateTimeLeft(poll?.creation_time, poll?.expiration)}</div>
            <div className="pollButtons">
                <div className="applyButton pollButton gradientBackground" onClick={()=>vote(poll?.poll_key, 1)}>Yes</div>
                <div className="applyButton pollButton gradientBackground" onClick={()=>vote(poll?.poll_key, 0)}>No</div>
            </div>

          </div>
        ))}
        </>
        :
        pollsloading ? <div className="pollCard" style={{fontSize:"14px", color:"gray"}}>Loading polls...</div> :
        <div className="pollCard">There are no polls in this community yet.</div>
        }
    </div>
  );
}

export default withUAL(Voting);
