import {useState, useEffect} from 'react';
import Drawer from '@mui/joy/Drawer';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Textarea from '@mui/joy/Textarea';
import Input from '@mui/joy/Input';
import { Scrollbars } from 'react-custom-scrollbars';
import { DataContext } from '../DataContext';
import { useContext } from 'react';
import PaymentHistory from './PaymentHistory';
import SweetAlert from './SweetAlert';

export default function EditApplication({workOffer}) {
  const { user, internalapi, setCurrentProfile, setView} = useContext(DataContext);

  const [open, setOpen] = useState(false);
  const [milestones, setMilestones] = useState([
    { title: '', duration: '', budget: '', details: '' }
  ]);
  const [formState, setFormState] = useState({
    offer_description: "",
    reason_for_match: ""
  });
  const [workProvider, setWorkProvider] = useState({});

  const addMilestone = () => {
    const newMilestone = { title: '', duration: '', budget: '', details: '' };
    setMilestones([...milestones, newMilestone]);
  };

  const handleInputChange = (index, event) => {
    const newMilestones = [...milestones];
    newMilestones[index][event.target.name] = event.target.value;
    setMilestones(newMilestones);
  };

  const handleFormInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  useEffect(() => {
    if (workOffer) {
      // Update form state
      setFormState({
        offer_description: workOffer.offer_description || "",
        reason_for_match: workOffer.reason_for_match || "",
      });
  
      // Construct milestones array
      const newMilestones = workOffer.milestone_name.map((name, index) => ({
        title: name,
        duration: workOffer.milestone_duration[index], // This needs to be derived or set as it's not directly available
        budget: workOffer.payment_per_milestone[index].replace(' USDT', ''), // Assuming you want to strip the 'USDT' part
        details: workOffer.milestone_description[index],
      }));
  
      setMilestones(newMilestones);
  
      // Set any other initial data here
      // For example, setWorkProvider(...)
    }
  },[workOffer?.work_application_id])
  

  const applySubmit = async () => {
    // Convert milestones to separate lists
    const milestone_names = milestones.map(m => m.title);
    const milestone_descriptions = milestones.map(m => m.details);
    const payment_per_milestone = milestones.map(m => `${parseFloat(m.budget).toFixed(4)} USDT`);
    const milestone_duration = milestones.map(m => m.duration);
    const token = await user.getIdToken();
  
    console.log({
      offer_description: formState?.offer_description,
      reason_for_match: formState?.reason_for_match,
      org_account: workOffer?.org_account,
      token,
      milestone_names,
      milestone_descriptions,
      payment_per_milestone,
      milestone_duration,
      work_offer_id: workOffer?.work_application_id ,
    })  
    // Use the converted lists in your POST request
    const response = await fetch(`${internalapi}/editApplyForWork`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        offer_description: formState?.offer_description,
        reason_for_match: formState?.reason_for_match,
        org_account: workOffer?.org_account,
        token,
        milestone_names,
        milestone_descriptions,
        payment_per_milestone,
        milestone_duration,
        work_offer_id: workOffer?.offer_id,
        work_application_id: workOffer?.work_application_id 
      }),
    });
    if(response.ok){
      SweetAlert("Application edited.")
    }
    else{
      SweetAlert("Application edit failed.")
    }
  };
  

  return (
    <>
      <button style={{marginTop:"20px"}} className="applyButton gradientBackground" onClick={() => {
        console.log(workOffer)
        setOpen(true)}}>Edit application</button>

      <Drawer
        size="md"
        variant="plain"
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        slotProps={{
          content: {
            sx: {
              bgcolor: 'transparent',
              p: { md: 3, sm: 0 },
              boxShadow: 'none',
              height: "85vh"
            },
          },
        }}
      >
        <Sheet
          sx={{
            borderRadius: 'md',
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: '100%',
            overflow: 'auto',
            padding:"0px"
          }}
        >

        <div className="applyJobHeader">Edit application</div>
        <Scrollbars style={{ height: "80vh" }} >

        <div className="applyJobWrapper">
          <div className="applyJobLeft">


                <div className="inputDescriptor">Why are you a good fit for the job?</div>
                <Textarea minRows={2} variant="outlined" placeholder="Example: I have 21 years of experience in React. I have a masters in computer science.
                I have built UI-s for the following projects on antelope:
                - google.com />" 
                name="reason_for_match"
                onChange={handleFormInputChange}
                value={formState.reason_for_match}
                />

                <div className="inputDescriptor">Describe your offer</div>
                <Textarea minRows={2} variant="outlined" placeholder="I can deliver it in 3 months. 
                  I will outsource milestones 2 and 3.
                  I can do it faster if I can also outsource milestone 1." 
                  onChange={handleFormInputChange}
                  name="offer_description"
                  value={formState.offer_description}
                  />
                
                <div className="inputDescriptor">Add milestones</div>
                {milestones.map((milestone, index) => (
                <div key={index} className="milestone">
                  <div className="milestone-header">
                    <Input
                      type="text"
                      name="title"
                      value={milestone.title}
                      onChange={(event) => handleInputChange(index, event)}
                      placeholder="Milestone title"
                    />
                    <Input
                      type="number"
                      name="duration"
                      value={milestone.duration}
                      onChange={(event) => handleInputChange(index, event)}
                      placeholder="Duration in days"
                    />
                    <Input
                      type="number"
                      name="budget"
                      value={milestone.budget}
                      onChange={(event) => handleInputChange(index, event)}
                      placeholder="Budget in USDT"
                    />
                  </div>
                  <Textarea
                    name="details"
                    value={milestone.details}
                    onChange={(event) => handleInputChange(index, event)}
                    placeholder="Milestone details/plan."
                    minRows={2}
                  />
                </div>
              ))}
              <button onClick={addMilestone} className="addMilestoneButton gradientBackground">
                Add milestone
              </button>
              <div className="submitApplicationButton gradientBackground" onClick={()=>applySubmit()}>Edit application</div>
          </div>
          </div>
          </Scrollbars>

        </Sheet>
      </Drawer>
    </>
  );
}
