import "./App.css";
import Feed from "./Views/Feed";
import Navbar from "./Views/Navbar";
import { useState, useEffect } from "react";
import WorkProviderWork from "./Views/WorkProviderWork"; // Renamed to start with a capital letter
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signOut
} from "firebase/auth";
import app from "./firebase-config";
import { DataContext } from "./DataContext";
import GetStarted from "./Views/GetStarted";
import Voting from "./Views/Voting";
import WorkProviderProfile from "./Views/WorkProviderProfile";
import Landing from "./Views/Landing";
import CreateJob from "./Views/CreateJob";
import WorkerWork from "./Views/WorkerWork";
import WorkerEditProfile from "./Views/WorkerEditProfile";
import WorkProviderEditProfile from "./Views/WorkProviderEditProfile";
import WorkerProfile from "./Views/WorkerProfile";
import EditJob from "./Views/EditJob";
import Admin from "./Views/Admin";
import UrlJob from "./Views/UrlJob";

import { UALProvider } from "ual-reactjs-renderer";
import { Anchor } from "ual-anchor";
import Loader from "./Views/Loader";

const appName = "JobBoard";

const internalapi = "https://jobboardapi-a3eb3fab92c6.herokuapp.com"; //heroku.....
//const internalapi = "http://localhost:3001"; //local
const chain = {
  chainId: "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906",
  rpcEndpoints: [
    {
      protocol: "https",
      host: "eos.eosusa.io",
      port: "",
    },
  ],
};

const anchor = new Anchor([chain], {
  appName,
});

const supportedChains = [chain];
const supportedAuthenticators = [anchor];

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

function App() {
  const [view, setView] = useState(""); // Updated to match the capitalized component name
  const [user, setUser] = useState("");
  const [workers, setWorkers] = useState([])
  const [chainaccount, setChainaccount] = useState("")
  const [accountType, setAccountType] = useState("")
  const [profile, setProfile] = useState({})
  const [currentProfile, setCurrentProfile] = useState()
  const [loading, setLoading] = useState(false)
  const [editCurrentJob, setEditCurrentJob] = useState({})
  const [refetchUserStatus, setRefetchUserStatus] = useState(0)
  const [workProviders, setWorkproviders] = useState([])
  const [urlWorkOffer, setUrlWorkOffer] = useState("")

  const signInWithGoogle = async () => {
    const result = await signInWithPopup(auth, provider);
    setUser(result.user);
    return result.user;
  };

  async function hashEmail(email) {
    // Encode the string into bytes
    const encoder = new TextEncoder();
    const data = encoder.encode(email);
    
    // Use the SubtleCrypto API to hash the data
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    
    // Convert the buffer to a hex string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }

  const logout = async () => {
    try {
      await signOut(auth);
      // After logging out, you might want to update your application state
      // For example, clear the user state and any other relevant states
      setUser(null);
      setChainaccount("");
      setAccountType("");
      setProfile({});
      // Optionally, redirect the user to the landing page or elsewhere
      setView("Feed");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const fetchChainAccount = async (user) => {
    const hashedEmail = await hashEmail(user.email)
    const response = await fetch(
      `https://eos.eosusa.io/v1/chain/get_table_rows`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: "jobboard1111",
          scope: "jobboard1111",
          table: "elinks1",
          index_position: 2,
          key_type: "sha256",
          lower_bound: hashedEmail,
          upper_bound: hashedEmail,
          json: true,
          limit: 1000,
        }),
      }
    );
    const data = await response.json();
    setChainaccount(data?.rows[0].account)



    let accountType = ""

    const checkorg = await fetch(
      `https://eos.eosusa.io/v1/chain/get_table_rows`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: "jobboard1111",
          scope: "jobboard1111",
          table: "orgprofil1",
          index_position: 1,
          key_type: "name",
          lower_bound: data?.rows[0].account,
          upper_bound: data?.rows[0].account,
          json: true,
          limit: 1000,
        }),
      }
    );
    const data1 = await checkorg.json();
    if(data1?.rows?.length > 0){
      accountType = "organisation"
    }

    //CHECK IF WORKER
    const checkworker = await fetch(
      `https://eos.eosusa.io/v1/chain/get_table_rows`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: "jobboard1111",
          scope: "jobboard1111",
          table: "workerprof2",
          index_position: 1,
          key_type: "name",
          lower_bound: data?.rows[0].account,
          upper_bound: data?.rows[0].account,
          json: true,
          limit: 1000,
        }),
      }
    );

    const data2 = await checkworker.json();
    if(data2?.rows?.length > 0){
      accountType = "worker"
    }
    setAccountType(accountType)
    setProfile(data2?.rows[0])

  }


  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setView("Feed")
        setUser(user);
        fetchChainAccount(user)
      } else {
        // User is signed out
        console.log("User is signed out");
        setView("Landing");
      }
    });
  }, [refetchUserStatus]);

  useEffect(()=>{
    //fetch workers
    const fetchWorkers = async () => {
      try {
        const response = await fetch(
          `https://eos.eosusa.io/v1/chain/get_table_rows`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: "jobboard1111",
              scope: "jobboard1111",
              table: "workerprof2",
              json: true,
              limit: 1000,
            }),
          }
        );
        const data = await response.json();
        setWorkers(data?.rows)
      } catch (error) {
        console.error("Error fetching workers:", error);
      }
      try {
        const response = await fetch(
          `https://eos.eosusa.io/v1/chain/get_table_rows`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: "jobboard1111",
              scope: "jobboard1111",
              table: "orgprofil1",
              json: true,
              limit: 1000,
            }),
          }
        );
        const data = await response.json();
        setWorkproviders(data?.rows)
      } catch (error) {
        console.error("Error fetching workers:", error);
      }
    };
    fetchWorkers();
  }, [])

  useEffect(() => {
    const updateProfileAndViewFromUrl = () => {
      const path = window.location.pathname.split('/');
      if(path[1] === "workoffer"){
        setView("UrlJob")
        setUrlWorkOffer(path[2])
      }
      if (path.length >= 3) {
        const profileType = path[1]; // 'worker' or 'workprovider'
        const account = path[2]; // 'workeraccount' or similar
  
        if (profileType && account) {
          setCurrentProfile(account);
          if (profileType === 'worker') {
            setView('WorkerProfile');
          } else if (profileType === 'workprovider') {
            setView('WorkProviderProfile');
          }
        }
      }
    };
     updateProfileAndViewFromUrl();
  }, []);

  return (
    <DataContext.Provider value={{ urlWorkOffer, setRefetchUserStatus, view, setView, signInWithGoogle, user, workers, workProviders, chainaccount, accountType, internalapi, profile, setProfile, logout, currentProfile, setCurrentProfile, loading, setLoading, setUser, editCurrentJob, setEditCurrentJob}}>
      {view === "Landing" ? (
        <Landing />
      ) : (
        <div className="app">
          <Navbar />
          {view === "Feed" ? (
            <Feed />
          ) : view === "WorkProviderWork" ? (
            <WorkProviderWork />
          ) : view === "EditJob" ? (
            <EditJob />
          ) : view === "CreateJob" ? (
            <CreateJob />
          ) : view === "GetStarted" ? (
            <GetStarted />
          ) : view === "WorkerWork" ? (
            <WorkerWork />
          ) : view === "WorkerEditProfile" ? (
            <WorkerEditProfile />
          ) : view === "WorkProviderEditProfile" ? (
            <WorkProviderEditProfile />
          ) : view === "Admin" ? (
            <Admin />
          ) : view === "UrlJob" ? (
            <UrlJob />
          ) : view === "Voting" ? (
            <UALProvider
              chains={supportedChains}
              authenticators={supportedAuthenticators}
              appName={appName}
            >
              <Voting />
            </UALProvider>
          ) : view === "WorkProviderProfile" ? (
            <WorkProviderProfile />
          ) : view === "WorkerProfile" ? (
            <WorkerProfile />
          ) : null}
        </div>
      )}
    </DataContext.Provider>
  );
}

export default App;
