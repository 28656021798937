import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyCnC1La2rKY3xzYq19iLEEv1HhXcsgOQEk",
    authDomain: "dainis.firebaseapp.com",
    projectId: "dainis",
    storageBucket: "dainis.appspot.com",
    messagingSenderId: "431258597954",
    appId: "1:431258597954:web:47ad0e146042af7207cbdb"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the storage service, which is used to create references in your storage bucket
// Create a root reference
const storage = getStorage();

const FileUpload = ({ setUrl }) => {
  const [logo, setLogo] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0]) {
      setLogo(acceptedFiles[0]);
      handleUpload(acceptedFiles[0])
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUpload = (logo) => {
    if (logo) {
      const storageRef = ref(storage, `landingpics/${logo.name}`);
      const uploadTask = uploadBytesResumable(storageRef, logo);
      console.log("Uploading")
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Progress updates
        },
        (error) => {
          console.log(error)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log('File available at', url);
            setUrl(url);
          });
        },
      );
    }
  };

  return (
    <div className="upload-logo-area">
      <div {...getRootProps()} className={`dropzone ${isDragActive ? 'drag-active' : ''}`}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the file here...</p>
        ) : logo ? (
          <p>{logo.name}</p>
        ) : (
          <p>Drag 'n' drop a file here, or click to select a file</p>
        )}
      </div>
    </div>
  );
};

export default FileUpload;