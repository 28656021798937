// src/firebase-config.js
import { initializeApp } from 'firebase/app';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCnC1La2rKY3xzYq19iLEEv1HhXcsgOQEk",
    authDomain: "dainis.firebaseapp.com",
    projectId: "dainis",
    storageBucket: "dainis.appspot.com",
    messagingSenderId: "431258597954",
    appId: "1:431258597954:web:47ad0e146042af7207cbdb"
  };

const app = initializeApp(firebaseConfig);

export default app;
