import "../App.css";
import { useState, useEffect } from "react";
//contexxt and usecontext
import { DataContext } from "../DataContext";
import { useContext } from "react";
import { Modal } from "@mui/joy";
import { Sheet } from "@mui/joy";
import { Textarea } from "@mui/joy";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Input from "@mui/joy/Input";
import EditApplication from "./EditApplication";
import SweetAlert from "./SweetAlert";


function WorkerWork() {
  const { user, workProviders, chainaccount, internalapi, profile, setProfile, setView, setCurrentProfile} = useContext(DataContext);
  const [workOffers, setWorkOffers] = useState([]);
  const [workFilter, setWorkfilter] = useState("All");
  const [currentWorkOffer, setCurrentWorkOffer] = useState();
  const [displayWorkOffers, setDisplayWorkOffers] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalMilestone, setModalMilestone] = useState(null);
  const [milestoneSubmission, setMilestoneSubmission] = useState();
  const [paymentPreference, setPaymentPreference] = useState("eosaccount");
  const [paymentInputState, setPaymentInputState] = useState("");
  const [refetch, setRefetch] = useState(0);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setMilestoneSubmission(value);
  };

  const setPaymentInput = (event) => {
    const { value } = event.target;
    setPaymentInputState(value);
  };

  const submitPaymentInfo = async () => {
    const token = await user.getIdToken();
    if(!paymentInputState){
      SweetAlert("Please fill in the field.")
      return
    }
    await fetch(`${internalapi}/submitpaymentinfo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, paymentPreference, paymentInputState }),
    });
    //set eos_account and exchanges_and_memos in profile to true
    setProfile({...profile, eos_account: true, exchanges_and_memos: true});
  };

  const submitMilestone = async () => {
    const token = await user.getIdToken();
    if(!milestoneSubmission){
      SweetAlert("Please fill in the field.")
      return
    }
    const response = await fetch(`${internalapi}/submitmilestone`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ 
        token,
        worker: chainaccount, 
        org_account: currentWorkOffer?.org_account, 
        work_application_id: currentWorkOffer?.work_application_id , 
        milestone_nr: modalMilestone, 
        milestone_submission_comment: milestoneSubmission
      }),
    });
    if(response?.ok){
      SweetAlert("Milestone submitted.")
      setOpen(false);
      setTimeout(() => {
        setRefetch(prevRefetch => prevRefetch + 1)
      }, 2000);
    }
    else{
      SweetAlert("Milestone submission failed.")
    }
  };

  const getApplicationStatus = (application, index) => {
    console.log(application)
    //FOR WORK PROVIDER
    {/*EXAMPLE of APPLICATION:
    {
      "payment_status": [
          0,
          0
      ],
      "milestone_submitted": [
          0,
          0
      ]
      "application_status": 0
    } */}

    let statusMessage;

    switch (application?.application_status) {
      case 0:
        statusMessage = "Waiting for application review"
        break;
      case 1:
        if (application?.milestone_submitted[index] == 0) {
          statusMessage = "Awaiting milestone submission";
          if(application?.payment_status[index] == 0){
            statusMessage = statusMessage +  ", payment not in escrow"
          }
          else if(application?.payment_status[index] == 1){
            statusMessage = statusMessage +  ", payment in escrow"
          }
        }
        else if (application?.milestone_submitted[index] == 1) {
          statusMessage = "Milestone under review";
          if(application?.payment_status[index] == 0){
            statusMessage = statusMessage +  ", payment not in escrow"
          }
          else if(application?.payment_status[index] == 1){
            statusMessage = statusMessage +  ", payment in escrow"
          }
          else if(application?.payment_status[index] == 2){
            statusMessage = "Milestone finished, payment made"
          }
        }
        break;
      case 2:
        statusMessage = "Application rejected"
        break;
      case 3:
        // Additional logic for when application_status is 3
        statusMessage = "Milestone finished, payment made";
        break;
      default:
        statusMessage = "";
        break;
    }
    console.log(statusMessage)
    return statusMessage
  }

  const getMilestoneButtons = (application, index) => {
    //FOR WORKER

    let buttons;

    switch (application?.application_status) {
      case 0:
        // If application_status is 0 / if first milestone / if last milestone is 1 etc.
        buttons = ""
        break;
      case 1:
        // Additional logic for when application_status is 1
        // application ongoing, has been accepted
        if (application?.milestone_submitted[index] !== 1) {
          buttons = <button className="gradientBackground applyButton" onClick={()=>{
            setModalMilestone(index);
            setOpen(true)}}>Submit milestone</button>
        }
        break;
      case 2:
        buttons = ""
        break;
      case 3:
        // Additional logic for when application_status is 3
        buttons = ""
        break;
      default:
        buttons = ""
        break;
    }

    return buttons
  
  }

  const getApplicationButtons = (application) => {
    if(application?.application_status == 0){
      return <EditApplication workOffer={application}/>
    }
    else{
      return null
    }
  }



  useEffect(()=>{
    if(workFilter === "All"){
      setDisplayWorkOffers(workOffers);
      return;
    }
    if(workFilter === "Pending"){
      setDisplayWorkOffers(workOffers.filter(workOffer => workOffer?.application_status == 0));
      return;
    }
    if(workFilter === "Accepted"){
      setDisplayWorkOffers(workOffers.filter(workOffer => workOffer?.application_status == 1));
      return;
    }
    if(workFilter === "Rejected"){
      setDisplayWorkOffers(workOffers.filter(workOffer => workOffer?.application_status == 2));
      return;
    }
  }, [workFilter])


  useEffect(() => {
    if(chainaccount){
    const fetchWork = async () => {
      let workList = [];
      try {
        const response = await fetch(
          `https://eos.eosusa.io/v1/chain/get_table_rows`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: "jobboard1111",
              scope: "jobboard1111",
              table: "workoffers2",
              json: true,
              limit: 1000,
            }),
          }
        );
        const data = await response.json();
        //join workoffers with applications based on offer_id in both
        workList = data?.rows;
        console.log(workList);
      } catch (error) {
        console.error("Error fetching work offers", error);
      }

      try {
        const response = await fetch(
          `https://eos.eosusa.io/v1/chain/get_table_rows`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: "jobboard1111",
              scope: "jobboard1111",
              table: "workaplictn3",
              index_position: 3,
              key_type: "name",
              lower_bound: chainaccount,
              upper_bound: chainaccount,
              json: true,
              limit: 1000,
            }),
          }
        );
        const data = await response.json();
        console.log("APPLICATIONS:");
        console.log(data?.rows);
        //join workoffers with applications based on offer_id in both. Result is workaplictn1, with workOffer field from worklist
        let workListWithApplications = data?.rows.map((application) => {
          let workOffer = workList.find(
            (workOffer) => workOffer.offer_id === application.offer_id
          );
          return { ...application, workOffer };
        });
        console.log(workListWithApplications);
        setWorkOffers(workListWithApplications);
        setDisplayWorkOffers(workListWithApplications);
        if(setCurrentWorkOffer){
          //find matching index and set to it
          let index = workListWithApplications.findIndex(workOffer => workOffer.offer_id == currentWorkOffer.offer_id);
          if(index > -1){
            setCurrentWorkOffer(workListWithApplications[index]);
          }
        }
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };
    fetchWork();
  }
  }, [chainaccount, refetch]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  return (
    <div className="workFeed">
      <div className="workFeedLeft">
        <div className="workFeedFilterCard">
          <div className="workFeedFilterCardHeader gradientBackground">
            <div className="areaDescriptor">Filter by work stage</div>
            <div className="workFeedFilterCardHeaderButtons">
              <div
                style={{ borderRadius: "10px 0px 0px 10px" }}
                className={`workFeedFilter ${
                  workFilter === "Pending" ? "workFeedFilterSelected" : ""
                }`}
                onClick={() => setWorkfilter("Pending")}
              >
                {" "}
                <img src="/card.svg" /> Pending
              </div>
              <div
                className={`workFeedFilter ${
                  workFilter === "Accepted" ? "workFeedFilterSelected" : ""
                }`}
                onClick={() => setWorkfilter("Accepted")}
              >
                <img src="/handshake.svg" /> Accepted
              </div>
              <div
                style={{ borderRadius: "0px 10px 10px 0px" }}
                className={`workFeedFilter ${
                  workFilter === "Rejected" ? "workFeedFilterSelected" : ""
                }`}
                onClick={() => setWorkfilter("Rejected")}
              >
                <img src="/cross.svg" /> Rejected
              </div>
            </div>
            <div
              className="resetFiltersWorkFeed"
              style={{ width: "80px" }}
              onClick={() => setWorkfilter("All")}
            >
              See all work
            </div>
          </div>
          <div className="workFeedFilterWorkCards">
            {displayWorkOffers?.length > 0 ? (
              displayWorkOffers?.map((workOffer, index) => {
                return (
                  <div
                    className="workFeedFilterWorkCard"
                    style={{
                      color:
                        workOffer?.offer_id == currentWorkOffer?.offer_id
                          ? "white"
                          : "#0B6BCB",
                      background:
                        workOffer?.offer_id == currentWorkOffer?.offer_id
                          ? "linear-gradient(135deg, #0B6BCB, #00D1FF)"
                          : "white",
                    }}
                    onClick={() => {
                      setCurrentWorkOffer(workOffer);
                      console.log(workOffer);
                    }}
                  >
                    <div className="workFeedFilterWorkCardTitle">
                      {workOffer?.workOffer?.work_title}
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                className="workFeedFilterWorkCard"
                style={{ fontSize: "14px", fontWeight: "700" }}
              >
                No work with these filters
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="workFeedRight">
        {currentWorkOffer && (
          <>
            <div className="workFeedMainCard">
              {" "}
              {/* Assuming each application has a unique 'id' */}
              <div className="workFeedJobCardTitle gradientText">
                {currentWorkOffer?.workOffer?.work_title}
              </div>
              <div style={{fontSize:"14px"}}>Application submitted on: {formatDate(currentWorkOffer?.creation_time)}</div>
              <div className="workProviderWorkWorkerInfo">
                <img
                  src={
                    workProviders?.find(
                      (worker) => worker?.org_account === currentWorkOffer?.org_account
                    )?.logo_image
                  }
                  height="32px"
                  width="32px"
                  alt="Profile"
                  style={{ borderRadius: "50%", cursor: "pointer" }}
                  onError={(e) =>
                    (e.currentTarget.src = "/no_provider_profile.jpg")
                  }
                  onClick={() => {
                    setCurrentProfile(currentWorkOffer?.org_account);
                    setView("WorkProviderProfile");
                  }}
                />
                <div
                  onClick={() => {
                    setCurrentProfile(currentWorkOffer?.org_account);
                    setView("WorkProviderProfile");
                  }}
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    cursor: "pointer",
                  }}
                >
                  {
                    workProviders?.find(
                      (worker) => worker?.org_account === currentWorkOffer?.org_account
                    )?.org_name
                  }
                </div>
              </div>
              {currentWorkOffer?.milestone_name?.map((milestone, index) => (
                <div className="workFeedMilestoneCard">
                  <div className="workFeedMilestoneCardHeader">
                    <div>
                      <div className="milestoneNumber gradientText">
                        Milestone {index + 1}
                      </div>
                      <div>
                        Payment:{" "}
                        {currentWorkOffer?.payment_per_milestone[index]}
                      </div>
                    </div>

                    <div
                      className="milestoneStatus"
                      style={{
                        display: getApplicationStatus(currentWorkOffer, index)
                          ? "block"
                          : "none",
                      }}
                    >
                      {getApplicationStatus(currentWorkOffer, index)}
                    </div>
                  </div>
                  <div className="workFeedMilestoneTitle">{milestone}</div>
                  <div className="workFeedMilestoneDescription">
                    {currentWorkOffer?.milestone_description[index]}
                  </div>
                  <div className="milestoneButtons">
                    {getMilestoneButtons(currentWorkOffer, index)}
                  </div>
                </div>
              ))}
              {getApplicationButtons(currentWorkOffer)}
            </div>
          </>
        )}
      </div>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          sx={{
            minWidth: "50vw",
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
            padding: "20px",
          }}
        >
          {profile?.eos_account || profile?.exchanges_and_memos?.length > 0 ? (
            <>
              {typeof modalMilestone !== "undefined" ? (
                <>
                  <div
                    className="gradientText"
                    style={{ fontSize: "20px", fontWeight: "700" }}
                  >
                    {currentWorkOffer?.workOffer?.work_title}
                  </div>
                  <div
                    className="gradientText"
                    style={{ fontSize: "14px", fontWeight: "700" }}
                  >
                    Milestone {modalMilestone + 1}
                  </div>
                  <div className="jobBudget gradientBackground">
                    Payment:{" "}
                    {currentWorkOffer?.payment_per_milestone[modalMilestone]}
                  </div>

                  <div className="jobRequirements" style={{marginTop:"5px"}}><b>Milestone name:</b> {currentWorkOffer?.milestone_name[modalMilestone]}</div>
                  <div className="jobRequirements" style={{marginTop:"5px"}}>
                    <b>Milestone description: </b>
                    {currentWorkOffer?.milestone_description[modalMilestone]}
                  </div>
                  <div className="inputLabel">
                    Overview of completed work for the milestone
                  </div>
                  <Textarea
                    className="inputField"
                    onChange={handleInputChange}
                    placeholder="Example: Link to figma is here: https://figma.com/sa9fsafas9. 
            I explained it in the call, recording is here: https://googledrive.com/3131jkkj1433"
                  ></Textarea>
                  <div
                    onClick={() => submitMilestone()}
                    className="applyButton gradientBackground"
                    style={{
                      width: "80px",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    Submit
                  </div>
                </>
              ) : null}
            </>
          ) : (
            <>
              <div
                className="gradientText"
                style={{ fontSize: "20px", fontWeight: "700" }}
              >
                Add your payment info
              </div>
              <div style={{ fontSize: "14px", color: "#0B6ECD" }}>
                Before submitting the milestone, please tell us how you'd like
                to get paid.
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                  flexWrap: "nowrap",
                  gap: "10px",
                }}
              >
                <Select
                  value={paymentPreference}
                  onChange={(e) => setPaymentPreference(e.target.value)}
                  sx={{
                    background: "white",
                    width: "100%",
                    marginTop: "10px",
                    boxSizing: "border-box",
                    borderRadius: "10px",
                    fontSize: "16px",
                    fontFamily: "inherit",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      outline: "none",
                    },
                    "&.Mui-focused": {
                      outline: "none",
                    },
                    "&:active": {
                      outline: "none",
                    },
                  }}
                >
                  <MenuItem value={"eosaccount"}>EOS account</MenuItem>
                  <MenuItem value={"coinbase"}>Coinbase</MenuItem>
                  <MenuItem value={"binance"}>Binance</MenuItem>
                </Select>
                {paymentPreference === "eosaccount" ? (
                  <Input
                    onChange={setPaymentInput}
                    placeholder="Your EOS account"
                    className="inputField"
                    style={{ width: "100%", marginTop: "10px" }}
                  ></Input>
                ) : paymentPreference === "coinbase" ? (
                  <Input
                    onChange={setPaymentInput}
                    placeholder="Your Coinbase memo"
                    className="inputField"
                    style={{ width: "100%", marginTop: "10px" }}
                  ></Input>
                ) : paymentPreference === "binance" ? (
                  <Input
                    onChange={setPaymentInput}
                    placeholder="Your Binance memo"
                    className="inputField"
                    style={{ width: "100%", marginTop: "10px" }}
                  ></Input>
                ) : null}
              </div>
              <button
                onClick={() => submitPaymentInfo()}
                className="applyButton gradientBackground"
                style={{ marginTop: "20px", width: "100%" }}
              >
                Submit
              </button>
            </>
          )}
        </Sheet>
      </Modal>
    </div>
  );
}

export default WorkerWork;
