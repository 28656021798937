import "../App.css";
import { DataContext } from "../DataContext";
import { useContext, useEffect, useState } from "react";
import Input from "@mui/joy/Input";
import { Textarea } from "@mui/joy";
import FileUpload from "./Fileupload";
import SweetAlert from "./SweetAlert";

function WorkProviderEditProfile() {
  const { view, setView, signInWithGoogle, user, chainaccount, internalapi } =
    useContext(DataContext);
  const [stage, setStage] = useState(0);
  const [type, setType] = useState("");
  const [url, setUrl] = useState("");

  const [formState, setFormState] = useState({
    name: "",
    projectDescription: "", // For work providers
    selfDescription: "", // For workers
    website: "",
    socialTelegram: "",
    socialTwitter: "",
    socialDiscord: "",
  });

  const [paymentState, setPaymentState] = useState({
    exchange: "",
    memo: "",
    eosaccount: "",
  });


  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(
          `https://eos.eosusa.io/v1/chain/get_table_rows`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: "jobboard1111",
              scope: "jobboard1111",
              table: "orgprofil1",
              key: "name",
              lower_bound: chainaccount,
              upper_bound: chainaccount,
              index_position: 1,
              json: true,
              limit: 1,
            }),
          }
        );
        const data = await response.json();

        setFormState({
          name: data?.rows[0]?.org_name,
          projectDescription: data?.rows[0]?.description,
          socialTelegram: data?.rows[0]?.socials?.find(key => key?.key?.includes("telegram"))?.value,
          socialTwitter: data?.rows[0]?.socials?.find(key => key?.key?.includes("twitter"))?.value,
          socialDiscord: data?.rows[0]?.socials?.find(key => key?.key?.includes("discord"))?.value,
          url: data?.rows[0]?.logo_image,
          website: data?.rows[0]?.website,
        });
        setUrl(data?.rows[0]?.logo_image);

      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };
    fetchProfile();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target; // Get the name and value from the event target
    setFormState((prevState) => ({
      ...prevState,
      [name]: value, // Use the input's name to determine which state property to update
    }));
  };

  const createProfileWorkProvider = async () => {
    console.log(formState)
    const token = await user.getIdToken();
    const response = await fetch(`${internalapi}/createprofileworkprovider`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, ...formState, url }),
    });
    if(response.ok){
      SweetAlert("Profile edited.");
    }
    else{
      SweetAlert("Profile edit failed.");
    }
  };

  return (
    <div className="getStarted">
      <div className="createProfileCard">
        <div className="createProfileCardHeader">Edit your profile</div>
        <div className="createProfileCardBody">
          <div className="inputDescriptor">Name</div>
          <Input
            name="name"
            value={formState.name}
            onChange={handleInputChange}
            placeholder="Elon Musk"
          />
          <div className="inputDescriptor">Describe your project</div>
          <Textarea
            name="projectDescription"
            value={formState.projectDescription}
            onChange={handleInputChange}
            minRows="2"
            placeholder="Describe your project"
          />
          <div className="inputDescriptor">Website</div>
          <Input
            name="website"
            value={formState.website}
            onChange={handleInputChange}
            placeholder="https://tesla.com"
          />
          <div className="inputDescriptor">Socials</div>
          <div className="createProfileSocials">
            <Input
              placeholder="Telegram handle"
              value={formState.socialTelegram}
              onChange={handleInputChange}
              name="socialTelegram"
            />
            <Input
              placeholder="Twitter handle"
              value={formState.socialTwitter}
              onChange={handleInputChange}
              name="socialTwitter"
            />
            <Input
              placeholder="Discord handle"
              value={formState.socialDiscord}
              onChange={handleInputChange}
              name="socialDiscord"
            />
          </div>
          <div className="inputDescriptor">Profile picture</div>
          {url && (
            <div style={{alignItems:"center", display:"flex", marginTop: "10px", marginBottom:"10px", gap:"10px"}}>
            <img
              src={url}
              alt="profile"
              style={{ width: "32px", height: "32px", borderRadius: "50%"}}
            />
            <a style={{color:"#0B6BCB", textDecoration:"none", fontWeight:"600", fontSize:"13px"}} href={url
            } target="_blank" >Open picture</a>
            </div>
          )}
          <FileUpload setUrl={setUrl} />
          <div className="createProfileButtons">
            <button
              className="applyButton gradientBackground"
              onClick={() => createProfileWorkProvider()}
            >
              Submit profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkProviderEditProfile;
