import "../App.css";
import { DataContext } from "../DataContext";
import { useContext, useEffect, useState } from "react";
import Drawer from "@mui/joy/Drawer";
import Sheet from "@mui/joy/Sheet";
import MakeDeposit from "./MakeDeposit";
import SweetAlert from "./SweetAlert";

function Navbar() {
  const {
    view,
    setView,
    user,
    setUser,
    chainaccount,
    accountType,
    logout,
    signInWithGoogle,
    setCurrentProfile,
  } = useContext(DataContext);
  const [makedepositopen, setMakeDepositOpen] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    console.log(view);
  }, [view]);

  const logmein = async () => {
    const useracc = await signInWithGoogle();
    const email = useracc?.email;
    async function hashEmail(email) {
      // Encode the string into bytes
      const encoder = new TextEncoder();
      const data = encoder.encode(email);

      // Use the SubtleCrypto API to hash the data
      const hashBuffer = await crypto.subtle.digest("SHA-256", data);

      // Convert the buffer to a hex string
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
      return hashHex;
    }
    const emailhash = await hashEmail(email);

    try {
      const response = await fetch(
        `https://eos.eosusa.io/v1/chain/get_table_rows`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            code: "jobboard1111",
            scope: "jobboard1111",
            table: "elinks1",
            key_type: "sha256",
            lower_bound: emailhash,
            upper_bound: emailhash,
            index_position: 2,
            json: true,
            limit: 1,
          }),
        }
      );

      const data = await response.json();
      console.log(data);
      if (data?.rows[0]) {
        console.log(data?.rows[0]);
        console.log(useracc);
        setUser(useracc);
      } else {
        logout();
        SweetAlert("Can not log in. Click on Get Started to create an account.");
      }
    } catch (error) {
      logout();
      SweetAlert("Can not log in. Click on Get Started to create an account.");
    }
  };

  useEffect(() => {
    //close the drawer when the view changes
    setOpen(false);
  }, [view]);

  return (
    <>
      <div className="navBar">
        <div onClick={() => setView("Feed")} className="brand gradientText">
          <img src="/portfolio.svg" height="36px" />
          JobBoard
        </div>
        <div className="navLinks">
          <div className="navLink" onClick={() => setView("Voting")}>
            Voting
          </div>
          {["lehestiklennar@gmail.com", "hramtsovvladislav@gmail.com", "melpearce1@gmail.com"].includes(user?.email) && (
            <div className="navLink" onClick={() => setView("Admin")}>
              Admin
            </div>
          )}
          {accountType === "worker" && (
            <div onClick={() => setView("WorkerWork")} className="navLink">
              My work
            </div>
          )}
          {accountType === "organisation" && (
            <div
              onClick={() => setView("WorkProviderWork")}
              className="navLink"
            >
              My work
            </div>
          )}
          {accountType === "worker" && (
            <div
            onClick={() =>{
              setCurrentProfile(chainaccount)
              setView("WorkerProfile")
            }}
              className="navLink"
            >
              Profile
            </div>
          )}
          {accountType === "organisation" && (
            <div
            onClick={() =>{
              setCurrentProfile(chainaccount)
              setView("WorkProviderProfile")
            }}              className="navLink"
            >
              Profile
            </div>
          )}
          {accountType === "organisation" && (
            <div onClick={() => setMakeDepositOpen(true)} className="navLink">
              Deposit
            </div>
          )}
          {user && (
            <div onClick={() => logout()} className="navLink">
              Log out
            </div>
          )}
          {accountType === "organisation" && (
            <button
              onClick={() => setView("CreateJob")}
              className="applyButton gradientBackground"
            >
              Create job
            </button>
          )}
          {accountType === "worker" && (
            <button
              onClick={() => setView("Feed")}
              className="applyButton gradientBackground"
            >
              Apply for jobs
            </button>
          )}
          {!user && (
            <div onClick={() => logmein()} className="navLink">
              Log in
            </div>
          )}
          {!user && (
            <button
              onClick={() => setView("GetStarted")}
              className="applyButton gradientBackground"
            >
              Get started
            </button>
          )}
        </div>
      </div>

      <div className="navBarMobile">
        <div onClick={() => setView("Feed")} className="brand gradientText">
          <img src="portfolio.svg" height="36px" />
          JobBoard
        </div>
        <div className="navLinks">
          <img onClick={() => setOpen(true)} src="menu.svg" />
        </div>
      </div>
      <MakeDeposit
        makedepositopen={makedepositopen}
        setMakeDepositOpen={setMakeDepositOpen}
      />
      <Drawer
        size="md"
        variant="plain"
        open={open}
        onClose={() => setOpen(false)}
        slotProps={{
          content: {
            sx: {
              bgcolor: "transparent",
              p: { md: 3, sm: 0 },
              boxShadow: "none",
            },
          },
        }}
      >
        <Sheet
          sx={{
            borderRadius: "md",
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <div className="mobileMenu">
            <div onClick={() => setView("Feed")} className="brand gradientText">
              <img src="/portfolio.svg" height="36px" />
              JobBoard
            </div>
              <div className="navLinkMobile" onClick={() => setView("Voting")}>
                Voting
              </div>
              {accountType === "worker" && (
                <div onClick={() => setView("WorkerWork")} className="navLinkMobile">
                  My work
                </div>
              )}
              {accountType === "organisation" && (
                <div
                  onClick={() => setView("WorkProviderWork")}
                  className="navLinkMobile"
                >
                  My jobs
                </div>
              )}
              {accountType === "worker" && (
                <div
                  onClick={() => setView("WorkerEditProfile")}
                  className="navLinkMobile"
                >
                  Edit profile
                </div>
              )}
              {accountType === "organisation" && (
                <div
                  onClick={() => setView("WorkProviderEditProfile")}
                  className="navLinkMobile"
                >
                  Edit profile
                </div>
              )}
              {accountType === "organisation" && (
                <div
                  onClick={() => setMakeDepositOpen(true)}
                  className="navLinkMobile"
                >
                  Deposit
                </div>
              )}
              {user && (
                <div onClick={() => logout()} className="navLinkMobile">
                  Log out
                </div>
              )}
              {accountType === "organisation" && (
                <button
                  onClick={() => setView("CreateJob")}
                  className="applyButton gradientBackground"
                >
                  Create job
                </button>
              )}
              {accountType === "worker" && (
                <button
                  onClick={() => setView("Feed")}
                  className="applyButton gradientBackground"
                >
                  Apply for jobs
                </button>
              )}
              {!user && (
                <div onClick={() => logmein()} className="navLinkMobile">
                  Log in
                </div>
              )}
              {!user && (
                <button
                  onClick={() => setView("GetStarted")}
                  className="applyButton gradientBackground"
                >
                  Get started
                </button>
              )}
          </div>
        </Sheet>
      </Drawer>
    </>
  );
}

export default Navbar;
