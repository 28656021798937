import {useState, useEffect} from 'react';
import {DataContext} from '../DataContext';
import {useContext} from 'react';
import AdminFileUpload from './AdminFileUpload';
import Input from '@mui/joy/Input';
import SweetAlert from './SweetAlert';

export default function Admin() {
  const { user, internalapi, setCurrentProfile, setView, accountType} = useContext(DataContext);
  const [url, setUrl] = useState("");
  const [formState, setFormState] = useState({
    name:"",
    description:"",
    unique_id:""
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const submit = async () => {
    const token = await user.getIdToken();
    console.log(formState)
    console.log(url)
    const response = await fetch(`${internalapi}/adminlanding`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, ...formState, url: url}),
    });
    if(response.ok){
      console.log("Success")
      SweetAlert("Project added.")
      setUrl("")
      setFormState({
        name:"",
        description:"",
        unique_id:""
      })
    }
    else{
      console.log("Failed")
      SweetAlert("Project add failed.")
    }
  }

  const submitremove = async () => {
    const token = await user.getIdToken();
    console.log(formState)
    const response = await fetch(`${internalapi}/adminlandingremove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, ...formState}),
    });
    if(response.ok){
      console.log("Success")
      SweetAlert("Project removed.")
    }
    else{
      console.log("Failed")
      SweetAlert("Project remove failed.")
    }
  }

  return (
    <>
    {["lehestiklennar@gmail.com", "hramtsovvladislav@gmail.com", "melpearce1@gmail.com"].includes(user?.email) ? (
        <div>
            <h1>Admin panel</h1>
            <h2>Add project</h2>
            <p>Upload images</p>
            <AdminFileUpload setUrl={setUrl}/>
            {url && <img height="300px" style={{marginTop:"20px"}} src={url} alt="uploaded" />}
            <Input style={{marginTop:"10px"}} placeholder="Project name" name="name" value={formState.name} onChange={handleInputChange} />
            <Input style={{marginTop:"10px"}}  placeholder="Project description" name="description" value={formState.description} onChange={handleInputChange} />
            <button className="gradientBackground applyButton" onClick={() => submit()} style={{marginTop:"20px"}}>Submit</button>
            <hr/>
            <h2>Remove project</h2>
            <Input placeholder="unique_id" name="unique_id" value={formState?.unique_id} onChange={handleInputChange} />
            <button className="gradientBackground applyButton" onClick={() => submitremove()} style={{marginTop:"20px"}}>Remove</button>
        </div>
    ) : (
        <div>
            <h1>You are not an admin</h1>
            <p>You are not authorized to view this page.</p>
        </div>
    )
    }
    </>
  );
}
