import {useState, useEffect} from 'react';
import Drawer from '@mui/joy/Drawer';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Textarea from '@mui/joy/Textarea';
import Input from '@mui/joy/Input';
import { Scrollbars } from 'react-custom-scrollbars';
import { DataContext } from '../DataContext';
import { useContext } from 'react';
import PaymentHistory from './PaymentHistory';
import SweetAlert from './SweetAlert';

export default function UrlJob() {
  const { user, internalapi, setCurrentProfile, setView, accountType, urlWorkOffer} = useContext(DataContext);

  const [open, setOpen] = useState(false);
    const [workOffer, setWorkOffer] = useState({});
  const [milestones, setMilestones] = useState([
    { title: '', duration: '', budget: '', details: '' }
  ]);
  const [formState, setFormState] = useState({
    offer_description: "",
    reason_for_match: ""
  });
  const [workProvider, setWorkProvider] = useState({});

  const addMilestone = () => {
    const newMilestone = { title: '', duration: '', budget: '', details: '' };
    setMilestones([...milestones, newMilestone]);
  };

  const handleInputChange = (index, event) => {
    const newMilestones = [...milestones];
    newMilestones[index][event.target.name] = event.target.value;
    setMilestones(newMilestones);
  };

  const handleFormInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  useEffect(() => {
    //fetch orgprofil b secondindex workOffer.org_account
        //fetch workoffers table from chain
        const fetchWorkProvider = async () => {
          try {
            const response = await fetch(
              `https://eos.eosusa.io/v1/chain/get_table_rows`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  code: "jobboard1111",
                  scope: "jobboard1111",
                  table: "orgprofil1",
                    lower_bound: workOffer?.org_account,
                    upper_bound: workOffer?.org_account,
                  json: true,
                  limit: 1,
                }),
              }
            );
            const data = await response.json();
            setWorkProvider(data?.rows[0])
          } catch (error) {
            console.error("Error fetching work offers", error);
          }
        };
        if(workOffer?.org_account){
          fetchWorkProvider();

        }
  }, [workOffer?.org_account])

  useEffect(() => {
    //fetch workoffers table from chain
    const fetchWorkOffersAndTags = async () => {
      let org_profiles;
      try {
        const response = await fetch(
          `https://eos.eosusa.io/v1/chain/get_table_rows`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: "jobboard1111",
              scope: "jobboard1111",
              table: "orgprofil1",
              json: true,
              limit: 1000,
            }),
          }
        );

        const data = await response.json();
        org_profiles = data?.rows;
      } catch (error) {
        console.error("Error fetching communities:", error);
      }

      try {
        const response = await fetch(
          `https://eos.eosusa.io/v1/chain/get_table_rows`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: "jobboard1111",
              scope: "jobboard1111",
              table: "workoffers2",
              json: true,
              limit: 1000,
            }),
          }
        );

        const data = await response.json();
        //add org profiles based on org_account in each profile and in each work offer
        data?.rows?.map((offer) => {
          offer.org_profile = org_profiles.find(
            (profile) => profile?.org_account === offer?.org_account
          );
        });

        const match = data?.rows?.find((offer) => offer?.offer_id == urlWorkOffer);

        setWorkOffer(match);
      } catch (error) {
        console.error("Error fetching communities:", error);
      }
    };
    fetchWorkOffersAndTags();
  }, []);

  const applySubmit = async () => {
    if(accountType == "organisation"){
      SweetAlert("Work providers cannot apply for work.")
      return
    }
    if(!user){
      SweetAlert("Please login to apply for work.")
      return
    }
    // Convert milestones to separate lists
    const milestone_names = milestones.map(m => m.title);
    const milestone_descriptions = milestones.map(m => m.details);
    const payment_per_milestone = milestones.map(m => `${parseFloat(m.budget).toFixed(4)} USDT`);
    const milestone_duration = milestones.map(m => m.duration);
    const token = await user.getIdToken();

    if(formState.offer_description === "" || formState.reason_for_match === "" || milestone_names.includes("") || payment_per_milestone.includes("") || milestone_duration.includes("")){
      SweetAlert("Please fill in all fields.")
      return
    }
  
  
    // Use the converted lists in your POST request
    const response = await fetch(`${internalapi}/applyForWork`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        offer_description: formState.offer_description,
        reason_for_match: formState.reason_for_match,
        org_account: workOffer?.org_account,
        token,
        milestone_names,
        milestone_descriptions,
        payment_per_milestone,
        milestone_duration,
        work_offer_id: workOffer?.offer_id,
      }),
    });
    if(response?.ok){
      SweetAlert("Thank you for your application. Good luck!")
        setView("Feed")
    }
    else{
      SweetAlert("Error submitting application.")
    }
  };
  

  return (
    <>

        <Scrollbars style={{ height: "85vh", marginTop:"50px"}} >

        <div className="applyJobWrapper">
          <div className="applyJobLeft">
            <div className="jobCardHeader">
                  <img src="https://wallpapers.com/images/featured/cool-profile-picture-87h46gcobjl5e4xu.jpg" height="24px" alt="Profile" className="profilePic" />
                  <div>
                    <h2 className="jobTitle gradientText">{workOffer?.work_title}</h2>
                    <div className="jobBudget gradientBackground">Budget: {workOffer?.total_budget}</div>
                  </div>
                </div>
                <p className="jobRequirements">
                  <b>Requirements:</b> {workOffer?.requirement}
                </p>

                <div className="inputDescriptor">Why are you a good fit for the job?</div>
                <Textarea minRows={2} variant="outlined" placeholder="Example: I have 21 years of experience in React. I have a masters in computer science.
                I have built UI-s for the following projects on antelope:
                - google.com />" 
                name="reason_for_match"
                onChange={handleFormInputChange}
                />

                <div className="inputDescriptor">Describe your offer</div>
                <Textarea minRows={2} variant="outlined" placeholder="I can deliver it in 3 months. 
                  I will outsource milestones 2 and 3.
                  I can do it faster if I can also outsource milestone 1." 
                  onChange={handleFormInputChange}
                  name="offer_description"
                  />
                
                <div className="inputDescriptor">Add milestones</div>
                {milestones.map((milestone, index) => (
                <div key={index} className="milestone">
                  <div className="milestone-header">
                    <Input
                      type="text"
                      name="title"
                      value={milestone.title}
                      onChange={(event) => handleInputChange(index, event)}
                      placeholder="Milestone title"
                    />
                    <Input
                      type="number"
                      name="duration"
                      value={milestone.duration}
                      onChange={(event) => handleInputChange(index, event)}
                      placeholder="Duration in days"
                    />
                    <Input
                      type="number"
                      name="budget"
                      value={milestone.budget}
                      onChange={(event) => handleInputChange(index, event)}
                      placeholder="Budget in USDT"
                    />
                  </div>
                  <Textarea
                    name="details"
                    value={milestone.details}
                    onChange={(event) => handleInputChange(index, event)}
                    placeholder="Milestone details/plan."
                    minRows={2}
                  />
                </div>
              ))}
              <button onClick={addMilestone} className="addMilestoneButton gradientBackground">
                Add milestone
              </button>
              <div className="submitApplicationButton gradientBackground" style={{background: accountType == "organisation" ? "gray" : ""}} onClick={()=>applySubmit()}>Submit application</div>
          </div>
          <div className="applyJobRight">
            <div className="applyJobProviderCard">
              <div className="applyJobProviderCardTitle">{workProvider?.org_name}</div>
              <div style={{wordWrap:"break-word"}}>{workProvider?.description}</div>
              <div style={{marginTop:"20px"}}>Paid out to workers</div>
              <div
                style={{ display: "flex", alignItems: "flex-end", gap: "5px" }}
              >
                <div
                  style={{
                    fontSize: "32px",
                    fontWeight: "800",
                    lineHeight: "30px",
                  }}
                >
                  {workProvider?.funds_distributed?.split(" ")[0]}
                </div>
                <div> {workProvider?.funds_distributed?.split(" ")[1]}</div>
              </div>
              <div style={{display:"flex", gap:"10px", marginTop:"10px"}}>
              <PaymentHistory account={workProvider?.org_account} type={"workprovider"}/>
              <div className="seePaymentsButton" onClick={()=>{
                setCurrentProfile(workProvider?.org_account);
                setView("WorkProviderProfile");
              }}>See profile</div>
              </div>

            </div>
          </div>
          </div>
          </Scrollbars>
    </>
  );
}
