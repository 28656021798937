import "../App.css";
import { DataContext } from "../DataContext";
import { useContext, useEffect, useState } from "react";
import Modal from '@mui/joy/Modal';
import Sheet from '@mui/joy/Sheet';
import Loader from './Loader';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import SweetAlert from './SweetAlert';

function MakeDeposit({makedepositopen, setMakeDepositOpen}) {
    const [deposit, setDeposit] = useState()
    const { user, chainaccount, internalapi} = useContext(DataContext);
    const [exchangeRate, setExchangeRate] = useState(0)
    const [refetchDeposit, setRefetchDeposit] = useState(0)
    const [formStateReturnDeposit, setFormStateReturnDeposit] = useState({
        amount: "",
        account: "",
        memo: ""
    })

    const handleReturnDepositChange = (e) => {
        const { name, value } = e.target;
        setFormStateReturnDeposit((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    }

    const unDeposit = async () => {
        const token = await user.getIdToken();
        if(formStateReturnDeposit.amount === "" || formStateReturnDeposit.account === ""){
            SweetAlert("Please fill in the fields.")
            return
        }
        const response = await fetch(`${internalapi}/returndeposit`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, eos_account: formStateReturnDeposit.account, quantity: formStateReturnDeposit.amount, memo: formStateReturnDeposit.memo}),
        });
        if(response.ok){
            SweetAlert("Undeposit successful.")
        }
        else{
            SweetAlert("Undeposit failed.")
        }
    }

    useEffect(() => {
        const fetchDeposit = async () => {
            try {
              const response = await fetch(
                `https://eos.eosusa.io/v1/chain/get_table_rows`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    code: "jobboard1111",
                    scope: "jobboard1111",
                    table: "deposits1",
                    lower_bound: chainaccount,
                    upper_bound: chainaccount,
                    json: true,
                    limit: 1000,
                  }),
                }
              );
      
              const data = await response.json();
              if(data?.rows[0]){
                setDeposit(data?.rows[0]?.total_amount)
              }
              else{
                setDeposit("0.0000 EOS")
              }
            } catch (error) {
              console.error("Error fetching communities:", error);
            }

            const res = await fetch(
                `https://eos.eosusa.io/v1/chain/get_table_rows`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    code: "swap.defi",
                    scope: "swap.defi",
                    table: "pairs1",
                    lower_bound: 12,
                    upper_bound: 12,
                    json: true,
                    limit: 1000,
                  }),
                }
              );
              const data1 = await res.json();
              setExchangeRate(data1?.rows[0].price0_last)
        }
        fetchDeposit();
    }, [refetchDeposit])
    return(
        <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={makedepositopen}
        onClose={() => setMakeDepositOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            padding:"20px",
            minWidth:"50vw"
          }}
        >
          <div className="gradientText" style={{fontSize:"20px", fontWeight:"700"}}>Make a deposit</div>
          <div className="inputDescriptor">Current balance</div>
          <div className="gradientBackground depositWrapper" style={{padding:"5px 7px", borderRadius:"5px", fontSize:"14px", color:"white", fontWeight:"700"}}>{deposit} ({(Number(deposit?.split(" ")[0]) / exchangeRate).toFixed(4) + " USDT"}) <img className="refetchDeposit" onClick={()=>setRefetchDeposit(prevRefetch => prevRefetch + 1)} src="/refetch.png" height="24px" /></div>

          <div className="inputDescriptor">Send EOS deposit to:</div>
          <Input
            value={"jobboard1111"}
            startDecorator={
                <Button variant="soft" color="neutral" onClick={() => navigator.clipboard.writeText("jobboard1111").then(() => {
                    // Optional: Display a message or perform an action to indicate copying was successful.
                    SweetAlert('Copied to clipboard');
                  }, (err) => {
                    // Optional: Handle any errors.
                    SweetAlert("Could not copy text.");
                  })}>
                    Copy
                  </Button>
            }
            sx={{ width: 300 }}
            />

          <div className="inputDescriptor">Add memo (important):</div>
          <Input
            value={chainaccount}
            startDecorator={
                <Button variant="soft" color="neutral" onClick={() => navigator.clipboard.writeText(chainaccount).then(() => {
                    // Optional: Display a message or perform an action to indicate copying was successful.
                    SweetAlert('Copied to clipboard');
                  }, (err) => {
                    // Optional: Handle any errors.
                    SweetAlert("Could not copy text.");
                  })}>
                    Copy
                  </Button>
            }
            sx={{ width: 300, marginBottom:"5px"}}
            />
          <a className="gradientText payCustomTokenButton" href="https://t.me/softwarefocus" target="_blank">
            🛈 Contact us to pay in a custom token
          </a>
            <hr style={{marginTop:"15px", marginBottom:"15px"}}/>
            <div className="gradientText" style={{fontSize:"20px", fontWeight:"700"}}>Withdraw deposit</div>
            <Input 
            name="amount"
            type="number"
            onChange={handleReturnDepositChange}
            placeholder="Amount" className="inputField" style={{width:"100%", marginTop:"10px"}}></Input>
            <Input 
            name="account"
            onChange={handleReturnDepositChange}
            placeholder="EOS account" className="inputField" style={{width:"100%", marginTop:"10px"}}></Input>
            <Input 
            name="memo"
            onChange={handleReturnDepositChange}
            placeholder="Memo" className="inputField" style={{width:"100%", marginTop:"10px"}}></Input>
            <button
            className="applyButton gradientBackground"
            style={{marginTop:"10px"}}
            onClick={()=>unDeposit()}
          >
            Withdraw
          </button>
        </Sheet>
      </Modal>
    )
}

export default MakeDeposit;