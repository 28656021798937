import React from "react";
import "../App.css";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ApplyJob from "./ApplyJob";
import { useEffect, useState, useContext } from "react";
import { DataContext } from "../DataContext";
import Autocomplete from "@mui/joy/Autocomplete";
import Button from "@mui/joy/Button";
import Loader from "./Loader";
// Define a simpler styled slider
const SimpleStyledSlider = styled(Slider)({
  color: "white", // Slider track and thumb color
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 27,
    width: 27,
    background: "linear-gradient(90deg, #0B6BCB, #00D1FF);",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(0, 0, 0, 0.16)",
    },
  },
  "& .MuiSlider-valueLabel": {
    color: "white", // Value label color
    background: "transparent",
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    backgroundColor: "white", // Default rail background color
    height: 3,
  },
});


function Feed() {
  const { setView, setCurrentProfile, loading, setLoading } = useContext(DataContext);
  const [workOffers, setWorkOffers] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);
  const [options, setOptions] = useState([]);
  const [displayWorkOffers, setDisplayWorkOffers] = useState([]);
  const [feedFilter, setFeedFilter] = useState("newest");
  // Step 1: Initialize the state for the slider values
  const [sliderValue, setSliderValue] = useState([0, 10000]);
  const [sliderDisplayValue, setSliderDisplayValue] = useState([0, 10000]);
  const [sliderMax, setSliderMax] = useState(10000);

  useEffect(() => {
    setSliderValue([0, sliderMax]);
    setSliderDisplayValue([0, sliderMax]);
  }, [sliderMax]);
  // Step 2: Update state on slider change
  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleSliderDisplayChange = (event, newValue) => {
    setSliderDisplayValue(newValue);
  }

  const handleTagInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleChange = (event, newValue) => {
    setSelectedTags(newValue);
    const newOptions = [...new Set([...options, ...newValue])];
    setOptions(newOptions);
  };

  useEffect(() => {
    const filteredWorkOffers = workOffers.filter((offer) => {
      return selectedTags.every((tag) => offer.tags.includes(tag));
    });
    const priceFilteredWorkOffers = filteredWorkOffers.filter(
      (offer) =>
        Number(offer.total_budget?.split(" ")[0]) >= sliderValue[0] &&
        Number(offer.total_budget?.split(" ")[0]) <= sliderValue[1]
    );
    //feedfilter can be newest, oldest by creation_time like 2024-03-21T12:06:13. Or by budget in total_budget. Filter by current feedFilter value.
    if (feedFilter === "newest") {
      priceFilteredWorkOffers.sort((a, b) => {
        return new Date(b.creation_time) - new Date(a.creation_time);
      });
    } else if (feedFilter === "oldest") {
      priceFilteredWorkOffers.sort((a, b) => {
        return new Date(a.creation_time) - new Date(b.creation_time);
      });
    } else if (feedFilter === "highest") {
      priceFilteredWorkOffers.sort((a, b) => {
        return Number(b.total_budget?.split(" ")[0]) - Number(a.total_budget?.split(" ")[0]);
      });
    } else if (feedFilter === "lowest") {
      priceFilteredWorkOffers.sort((a, b) => {
        return Number(a.total_budget?.split(" ")[0]) - Number(b.total_budget?.split(" ")[0]);
      });
    }
    setDisplayWorkOffers(priceFilteredWorkOffers);
  }, [selectedTags, sliderValue, feedFilter]);

  useEffect(() => {
    setLoading(true);
    //fetch workoffers table from chain
    const fetchWorkOffersAndTags = async () => {
      let org_profiles;
      try {
        const response = await fetch(
          `https://eos.eosusa.io/v1/chain/get_table_rows`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: "jobboard1111",
              scope: "jobboard1111",
              table: "orgprofil1",
              json: true,
              limit: 1000,
            }),
          }
        );

        const data = await response.json();
        org_profiles = data?.rows;
      } catch (error) {
        console.error("Error fetching communities:", error);
      }

      try {
        const response = await fetch(
          `https://eos.eosusa.io/v1/chain/get_table_rows`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: "jobboard1111",
              scope: "jobboard1111",
              table: "workoffers2",
              json: true,
              limit: 1000,
            }),
          }
        );

        const data = await response.json();
        //add org profiles based on org_account in each profile and in each work offer
        data?.rows?.map((offer) => {
          offer.org_profile = org_profiles.find(
            (profile) => profile?.org_account === offer?.org_account
          );
        });

        //find biggest total_budget. is with USDT appended.
        let max = 0;
        data?.rows?.map((offer) => {
          let budget = Number(offer.total_budget?.split(" ")[0]);
          if (budget > max) {
            max = budget;
          }
        });
        setSliderMax(max);
                //sort by newest first
        data?.rows.sort((a, b) => {
          return new Date(b.creation_time) - new Date(a.creation_time);
        });

        // remove where active == 0
        data.rows = data?.rows.filter((offer) => offer.active === 1);
        setWorkOffers(data?.rows);
        setDisplayWorkOffers(data?.rows);
      } catch (error) {
        console.error("Error fetching communities:", error);
      }

      try {
        const response = await fetch(
          `https://eos.eosusa.io/v1/chain/get_table_rows`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: "jobboard1111",
              scope: "jobboard1111",
              table: "tagid1",
              json: true,
              limit: 1000,
            }),
          }
        );
        const data = await response.json();
        let taglist = [];
        data?.rows?.map((tag) => {
          taglist.push(tag?.tag);
        });
        setOptions(taglist);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
      setLoading(false);
    };
    fetchWorkOffersAndTags();
  }, []);

  const resetAllFilters = () => {
    setSelectedTags([]);
    setSliderValue([0, sliderMax]);
    setSliderDisplayValue([0, sliderMax]);
    setFeedFilter("newest");
  }

  return (
    <div className="feed">
      {!loading ?
      <>
      <div className="feedLeft">
        <div className="feedFilterCard gradientBackground">
          <div className="textMedium" style={{marginBottom:"10px"}}>Pay range in USDT</div>
          <SimpleStyledSlider
            value={sliderDisplayValue} // Step 3: Control the slider with the state variable
            onChangeCommitted={handleSliderChange} // Call the event handler on change
            onChange={handleSliderDisplayChange}
            defaultValue={sliderDisplayValue}
            min={0}
            step={10}
            max={sliderMax}
            valueLabelDisplay="auto"
            sx={{
              marginTop: "10px",
              "& .MuiSlider-thumb": {},
              "& .MuiSlider-valueLabel": {
                top: "15px",
                "& > *": {
                  transform: "translateY(-50%) !important",
                },
              },
            }}
          />
          <div className="textMedium" style={{marginTop:"15px", marginBottom:"-5px"}}>Tags</div>

          <Autocomplete
            multiple
            value={selectedTags}
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={handleTagInputChange}
            options={options}
            sx={{marginTop:"10px", fontFamily: "inherit"}}
            placeholder="List of tags"
          />

         <div className="textMedium" style={{marginTop:"15px", marginBottom:"-5px"}}>Sort by</div>

          <Select
            value={feedFilter}
            onChange={(e) => setFeedFilter(e.target.value)}
            label="Age"
            sx={{
              background: "white",
              width: "100%",
              marginTop: "10px",
              boxSizing: "border-box",
              borderRadius: "10px",
              fontFamily: "inherit",
              fontSize: "16px",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
                outline: "none",
              },
              "&.Mui-focused": {
                outline: "none",
              },
              "&:active": {
                outline: "none",
              },
            }}
          >
            <MenuItem value={"newest"}>New first</MenuItem>
            <MenuItem value={"oldest"}>Oldest first</MenuItem>
            <MenuItem value={"highest"}>Highest budget</MenuItem>
            <MenuItem value={"lowest"}>Lowest budget</MenuItem>
          </Select>
          <div className="resetFilters" onClick={()=>resetAllFilters()}>Reset all</div>
        </div>
      </div>
      <div className="feedRight">
        {displayWorkOffers?.length === 0 && <div className="noJobs">No jobs found</div>}
        {displayWorkOffers?.map((workOffer) => (
          <div className="jobCard">
            <div className="jobCardHeader">
              <img
                src={workOffer?.org_profile?.logo_image}
                alt="Profile"
                className="profilePic"
                onError={(e) => e.currentTarget.src ='/no_provider_profile.jpg'}
                onClick={() =>{
                  setCurrentProfile(workOffer?.org_account)
                  setView("WorkProviderProfile")
                }}
              />
              <div>
                <h2 className="jobTitle gradientText">
                  {workOffer?.work_title ? workOffer?.work_title : "No title"}
                </h2>
                <div className="jobBudget gradientBackground">
                  Budget: {workOffer?.total_budget}
                </div>
              </div>
            </div>
            <p className="jobDescription">{workOffer?.work_description ? workOffer?.work_description : "No description."}</p>
            <div className="jobFooter">
              <div className="jobTags">
                {workOffer?.tags.map((tag) => (
                  <span className="jobTag">{tag}</span>
                ))}
              </div>
              <div className="gradient-container" >
              <div className="fade-effect-right" style={{height:"30px"}}></div>
            </div>
              <ApplyJob workOffer={workOffer}/>
            </div>
          </div>
        ))}
      </div>
      </>
    : <Loader />}
    </div>
  );
}

export default Feed;
