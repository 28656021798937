import "../App.css";
import { DataContext } from "../DataContext";
import { useContext, useEffect, useState } from "react";
import Input from "@mui/joy/Input";
import { Textarea } from "@mui/joy";
import FileUpload from "./Fileupload";
import SweetAlert from "./SweetAlert";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function WorkerEditProfile() {
  const { view, setView, signInWithGoogle, user, chainaccount, internalapi } =
    useContext(DataContext);
  const [stage, setStage] = useState(0);
  const [type, setType] = useState("");
  const [url, setUrl] = useState("");
  const [paymentPreference, setPaymentPreference] = useState("eosaccount");
  const [paymentInputState, setPaymentInputState] = useState("");

  const setPaymentInput = (event) => {
    const { value } = event.target;
    console.log(value);
    setPaymentInputState(value);
  };

  const [formState, setFormState] = useState({
    name: "",
    projectDescription: "", // For work providers
    selfDescription: "", // For workers
    website: "",
    socialTelegram: "",
    socialTwitter: "",
    socialDiscord: "",
  });

  const [paymentState, setPaymentState] = useState({
    exchange: "",
    memo: "",
    eosaccount: "",
  });

  useEffect(() => {
    console.log(paymentState)
  }, [paymentState])

  const submitPaymentInfo = async () => {
    const token = await user.getIdToken();
    console.log(paymentState);
    if(paymentInputState === "") {
      SweetAlert("Please fill in the payment info.")
      return
    }
    const response = await fetch(`${internalapi}/addpaymentinfo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, paymentInputState, paymentPreference}),
  });
    if(response.ok){
      SweetAlert("Payment info added.")
    }
    else{
      SweetAlert("Error adding payment info.")
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(
          `https://eos.eosusa.io/v1/chain/get_table_rows`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: "jobboard1111",
              scope: "jobboard1111",
              table: "workerprof2",
              key: "name",
              lower_bound: chainaccount,
              upper_bound: chainaccount,
              index_position: 1,
              json: true,
              limit: 1,
            }),
          }
        );
        const data = await response.json();
        // Assuming the logic to determine payment preference and input state goes here
        // Example logic (adjust according to your actual data structure)
        let pref = "eosaccount"; // Default preference
        let inputState = data?.rows[0]?.eos_account; // Default input state
        if (data?.rows[0]?.exchanges_and_memos?.length > 0) {
          // Example logic to set preference based on fetched data
          pref = data.rows[0].exchanges_and_memos[0].key;
          inputState = data.rows[0].exchanges_and_memos[0].value;
        }
        setPaymentPreference(pref);
        setPaymentInputState(inputState);
  
        console.log(data?.rows[0]?.profile_pic + "URL")
        setUrl(data?.rows[0]?.profile_pic);
        setFormState({
          name: data?.rows[0]?.username,
          selfDescription: data?.rows[0]?.description,
          socialTelegram: data?.rows[0]?.socials?.find(key => key?.key?.includes("telegram"))?.value,
          socialTwitter: data?.rows[0]?.socials?.find(key => key?.key?.includes("twitter"))?.value,
          socialDiscord: data?.rows[0]?.socials?.find(key => key?.key?.includes("discord"))?.value,
          url: data?.rows[0]?.profile_pic,
        });
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };
    fetchProfile();
  }, []);
  

  const handleInputChange = (event) => {
    const { name, value } = event.target; // Get the name and value from the event target
    setFormState((prevState) => ({
      ...prevState,
      [name]: value, // Use the input's name to determine which state property to update
    }));
  };

  const createProfileWorker = async () => {
    const token = await user.getIdToken();
    await fetch(`${internalapi}/createprofileworker`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, ...formState, url }),
    });

    SweetAlert("Profile edited.");
  };

  return (
    <div className="getStarted">
      <div className="createProfileCard">
        <div className="createProfileCardHeader">Edit your profile</div>
        <div className="createProfileCardBody">
          <div className="inputDescriptor">Name</div>
          <Input
            name="name"
            value={formState.name}
            onChange={handleInputChange}
            placeholder="Elon Musk"
          />
          <div className="inputDescriptor">Describe yourself</div>
          <Textarea
            name="selfDescription"
            value={formState.selfDescription}
            onChange={handleInputChange}
            minRows="2"
            placeholder="Describe yourself"
          />
          <div className="inputDescriptor">Socials</div>
          <div className="createProfileSocials">
            <Input
              placeholder="Telegram handle"
              value={formState.socialTelegram}
              onChange={handleInputChange}
              name="socialTelegram"
            />
            <Input
              placeholder="Twitter handle"
              value={formState.socialTwitter}
              onChange={handleInputChange}
              name="socialTwitter"
            />
            <Input
              placeholder="Discord handle"
              value={formState.socialDiscord}
              onChange={handleInputChange}
              name="socialDiscord"
            />
          </div>
          <div className="inputDescriptor">Profile picture</div>
          {url && (
            <div style={{alignItems:"center", display:"flex", marginTop: "10px", marginBottom:"10px", gap:"10px"}}>
            <img
              src={url}
              alt="profile"
              style={{ width: "32px", height: "32px", borderRadius: "50%"}}
            />
            <a style={{color:"#0B6BCB", textDecoration:"none", fontWeight:"600", fontSize:"13px"}} href={url
            } target="_blank" >Open picture</a>
            </div>
          )}
          <FileUpload setUrl={setUrl} />
          <div className="createProfileButtons">
            <button
              className="applyButton gradientBackground"
              onClick={() => createProfileWorker()}
            >
              Submit profile
            </button>
          </div>
          <hr></hr>
          <div className="inputLabel gradientText" style={{fontSize:"16px", fontWeight:"700"}}>Add payment info</div>
          <div style={{display:"flex", flexDirection:"row", marginTop:"10px", flexWrap:"nowrap", gap:"10px"}}>
          <Select
            value={paymentPreference}
            onChange={(e) => setPaymentPreference(e.target.value)}
            sx={{
              background: "white",
              width: "100%",
              marginTop: "10px",
              boxSizing: "border-box",
              borderRadius: "10px",
              fontSize: "16px",
              fontFamily:"inherit",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
                outline: "none",
              },
              "&.Mui-focused": {
                outline: "none",
              },
              "&:active": {
                outline: "none",
              },
            }}
          >
            <MenuItem value={"eosaccount"}>EOS account</MenuItem>
            <MenuItem value={"coinbase"}>Coinbase</MenuItem>
            <MenuItem value={"binance"}>Binance</MenuItem>
          </Select>
          {paymentPreference === "eosaccount" ?
          <Input value={paymentInputState} onChange={setPaymentInput} placeholder="Your EOS account" className="inputField" style={{width:"100%", marginTop:"10px"}}></Input>
          : paymentPreference === "coinbase" ?
          <Input value={paymentInputState} onChange={setPaymentInput} placeholder="Your Coinbase memo" className="inputField" style={{width:"100%", marginTop:"10px"}}></Input>
          : paymentPreference === "binance" ?
          <Input value={paymentInputState} onChange={setPaymentInput} placeholder="Your Binance memo" className="inputField" style={{width:"100%", marginTop:"10px"}}></Input>
          : null
          }
          </div>
          <button
            className="applyButton gradientBackground"
            onClick={() => submitPaymentInfo()}
            style={{marginTop:"10px"}}
          >
            Submit payment info
          </button>
        </div>
      </div>
    </div>
  );
}

export default WorkerEditProfile;
