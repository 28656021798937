import React, { useState, useRef, useEffect } from "react";
import { Rating } from "react-simple-star-rating";
import "../App.css";
import Modal from '@mui/joy/Modal';
import Sheet from '@mui/joy/Sheet';
import Textarea from '@mui/joy/Textarea';
import SweetAlert from "./SweetAlert";
import { DataContext } from "../DataContext";
import { useContext } from "react";
import PaymentHistory from "./PaymentHistory";

function WorkerProfile() {
  const { view, setView, user, workers, internalapi, currentProfile, chainaccount } = useContext(DataContext);

  const [rating, setRating] = useState(0);
  const [showBackButton, setShowBackButton] = useState(false);
  const scrollContainer = useRef(null);
  const [open, setOpen] = useState(false);
  const [workerProfile, setWorkerProfile] = useState({});
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [refetch, setRefetch] = useState(0)
  const [replyOpen, setReplyOpen] = useState(false);
  const [reviewForReply, setReviewForReply] = useState({});

  const [formState, setFormState] = useState({
    review: "",
    reviewreply: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target; // Get the name and value from the event target
    setFormState((prevState) => ({
      ...prevState,
      [name]: value, // Use the input's name to determine which state property to update
    }));
  };

  const leaveReview = async() => {
    if(!formState.review) return SweetAlert("Please fill in the review.")
    const token = await user.getIdToken();
    const response = await fetch(`${internalapi}/leavereview`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, ...formState, rating: rating, reviewee: workerProfile?.worker }),
    });
    if (response.ok) {
      setOpen(false);
      SweetAlert("Review submitted");
      setTimeout(() => {
        setRefetch(prevrefetch => prevrefetch + 1);
      }, 1000);
    }
  };

  const replyToReview = async() => {
    if(!formState.reviewreply) return SweetAlert("Please fill in the reply.")
    const token = await user.getIdToken();
    const response = await fetch(`${internalapi}/replytoreview`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, ...formState, reviewer: reviewForReply?.reviewer, review_id: reviewForReply?.unique_review_id, reviewee: workerProfile?.worker}),
    });
    if (response.ok) {
      setReplyOpen(false);
      SweetAlert("Reply submitted");
      setTimeout(() => {
        setRefetch(prevrefetch => prevrefetch + 1);
      }, 2000);
    }
  }

  useEffect(() => {
    //fetch workoffers table from chain
    const fetchWorkProviderProfile = async () => {
      try {
        const response = await fetch(
          `https://eos.eosusa.io/v1/chain/get_table_rows`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: "jobboard1111",
              scope: "jobboard1111",
              table: "workerprof2",
              lower_bound: currentProfile,
              upper_bound: currentProfile,
              json: true,
              limit: 1,
            }),
          }
        );

        const data = await response.json();
        setWorkerProfile(data?.rows[0]);
        
      } catch (error) {
        console.error("Error fetching communities:", error);
      }

            //fetch all worker profiles and all work provider profiles
            let all_workers;
            let all_work_providers;
            try{
              const response = await fetch(
                `https://eos.eosusa.io/v1/chain/get_table_rows`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    code: "jobboard1111",
                    scope: "jobboard1111",
                    table: "workerprofil1",
                    json: true,
                    limit: 1000,
                  }),
                }
              );
              const data = await response.json();
              all_workers = data?.rows;
            }
            catch (error) {
              console.error("Error fetching communities:", error);
            }
      
            try{
              const response = await fetch(
                `https://eos.eosusa.io/v1/chain/get_table_rows`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    code: "jobboard1111",
                    scope: "jobboard1111",
                    table: "orgprofil1",
                    json: true,
                    limit: 1000,
                  }),
                }
              );
              const data = await response.json();
              all_work_providers = data?.rows;
            }
            catch (error) {
              console.error("Error fetching communities:", error);
            }

      try {
        const response = await fetch(
          `https://eos.eosusa.io/v1/chain/get_table_rows`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: "jobboard1111",
              scope: currentProfile,
              table: "reviews1",
              json: true,
              limit: 1000,
            }),
          }
        );

        const data = await response.json();
        const updatedReviews = data?.rows?.map(row => {
          const workProvider = all_work_providers?.find(provider => provider.org_account === row.reviewer);
          const worker = all_workers?.find(worker => worker.worker === row.reviewer);
        
          return {
            ...row,
            realname: workProvider ? workProvider.org_name : worker ? worker.username : undefined,
            pic: workProvider ? workProvider.logo_image : worker ? worker.profile_pic : undefined,
          };
        });
        setReviews(updatedReviews)
        // Calculate average rating from row.rating, in one line
        const avgrating = data?.rows.reduce((acc, row) => acc + row.rating, 0) / data?.rows.length
        setAverageRating(Math.round(avgrating, 2));
        
      } catch (error) {
        console.error("Error fetching communities:", error);
      }
    };
    fetchWorkProviderProfile();
  }, [refetch]);


  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
    // other logic
  };

  const gradientColors = [
    "#0B6BCB",
    "#2C7DD9",
    "#4D9EE7",
    "#6EBFF5",
    "#90D0FF",
  ];

  const checkScroll = () => {
    if (scrollContainer.current) {
      setShowBackButton(scrollContainer.current.scrollLeft > 0);
    }
  };

  const scroll = (scrollOffset) => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollBy({
        left: scrollOffset,
        behavior: "smooth",
      });
    }
  };

  // Set up the event listener
  useEffect(() => {
    if(!scrollContainer.current) return;
    const container = scrollContainer.current;
    container.addEventListener("scroll", checkScroll);

    // Clean up the event listener
    return () => container.removeEventListener("scroll", checkScroll);
  }, []);

  return (
    <div className="profilePage">

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            padding:"20px",
            minWidth:"50vw"
          }}
        >
          <div className="gradientText" style={{fontSize:"24px", fontWeight:"600"}}>
            Leave a review for {workerProfile?.username}
          </div>
          <Rating onClick={handleRating} fillColorArray={gradientColors} size="28" />
          <Textarea sx={{marginTop:"20px"}} placeholder="Write your review here" minRows={4} onChange={handleInputChange} name="review"/>
          <button className="applyButton gradientBackground" style={{marginTop:"20px", width:"100%"}} onClick={() => leaveReview()}>Submit</button>
        </Sheet>
      </Modal>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={replyOpen}
        onClose={() => setReplyOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            padding:"20px",
            minWidth:"50vw"
          }}
        >
          <div className="gradientText" style={{fontSize:"24px", fontWeight:"600"}}>
            Review by {reviewForReply?.realname}
          </div>
          <div className="inputDescriptor" style={{marginBottom:"-10px"}}>Review</div>
          <div style={{marginTop:"10px"}}>{reviewForReply?.review}</div>
          {reviewForReply?.reply_to_review &&
          <div className="reviewreply">
          <div className="inputDescriptor" style={{marginBottom:"-10px"}}>Reply from {workerProfile?.username}</div>
          <div style={{marginTop:"10px"}}>{reviewForReply?.reply_to_review}</div>
          </div>}
          <div style={{display: workerProfile?.worker == chainaccount ? "block" : "none"}}>
          <Textarea sx={{marginTop:"20px"}} placeholder="Write your reply here" minRows={4} onChange={handleInputChange} name="reviewreply"/>
          <button className="applyButton gradientBackground" style={{marginTop:"20px", width:"100%"}} onClick={() => replyToReview()}>Submit</button>
          </div>
        </Sheet>
      </Modal>


      <div className="profilePageCard">
        <div className="profilePageCardHeader gradientBackground">
        <div
            style={{
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              border: "2px solid white",
              backgroundPosition: 'center', // Center the background image
              backgroundSize: 'cover', // Cover the entire div without stretching the image
              backgroundImage: `url(${workerProfile?.profile_pic})`
            }}
          />
          <div><div>{workerProfile?.username}</div><div style={{fontSize:"14px", fontWeight:"500"}}>Worker</div></div>

          <div className="editProfileButton" onClick={()=>{
            setView("WorkerEditProfile")
          }}>
            <img src="/edit.png" alt="Edit" />
          </div>
        </div>
        <div className="profilePageCardBody">
          <div className="profilePageCardBodyTop">
            <div className="profilePageCardBodyTopLeft">
              <div
                className="gradientText"
                style={{ fontSize: "18px", fontWeight: "700", wordWrap:"break-word"}}
              >
                About {workerProfile?.username}
              </div>
              <div>{workerProfile?.description}</div>
              <div className="profilePageCardBodyButtons">
                <a
                  className="applyButton gradientBackground"
                  href={`https://t.me/${workerProfile?.socials?.find(social => social.key === "telegram")?.value}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{display: workerProfile?.socials?.find(social => social.key === "telegram")?.value ? "block" : "none"}}
                >
                  Telegram
                </a>
                <a
                  className="applyButton gradientBackground"
                  href={`https://twitter.com/${workerProfile?.socials?.find(social => social.key === "twitter")?.value}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{display: workerProfile?.socials?.find(social => social.key === "twitter")?.value ? "block" : "none"}}
                >
                  Twitter
                </a>
                <a className="applyButton gradientBackground" href={workerProfile?.website} style={{display: workerProfile?.website ? "block" : "none"}}>Website</a>
              </div>
            </div>
            <div className="profilePageCardBodyTopRight gradientBackground">
              <div>Payments received</div>
              <div
                style={{ display: "flex", alignItems: "flex-end", gap: "5px" }}
              >
                <div
                  style={{
                    fontSize: "32px",
                    fontWeight: "800",
                    lineHeight: "30px",
                  }}
                >
                  {workerProfile?.funds_earned?.split(" ")[0]}
                </div>
                <div> {workerProfile?.funds_earned?.split(" ")[1]}</div>
              </div>
              <PaymentHistory account={workerProfile?.worker} type={"worker"}/>
            </div>
          </div>
          <div>
            <div
              className="gradientText"
              style={{
                fontSize: "16px",
                fontWeight: "700",
                marginBottom: "5px",
              }}
            >
              Reviews from work providers
            </div>
            <Rating
              fillColorArray={gradientColors}
              size="28"
              initialValue={averageRating}
              readonly="true"
            />
          </div>
          {reviews.length > 0 &&
          <div style={{ position: "relative" }}>
            <div
              onClick={() => scroll(-350)}
              className="scroll-button-back"
              style={{ visibility: showBackButton ? "visible" : "hidden" }}
            >
              <img
                src="/scroll-right.png"
                height="48px"
                className="scroll-left-image"
              />
            </div>

            <div className="scrollable-container" ref={scrollContainer}>
              {/* Your cards go here */}
              {reviews?.map((review) => (
                <div className="reviewCard">
                    <div className="readReview" onClick={()=>{
                      setReviewForReply(review)
                      setReplyOpen(true)}}>Open review</div>
                  <div className="reviewCardTop">


                    <img
                      src={review?.pic}
                      onError={(e) => e.currentTarget.src ='/no_provider_profile.jpg'}
                      alt="Avatar"
                      style={{
                        width: "36px",
                        height: "36px",
                        borderRadius: "50%",
                        border: "2px solid white",
                      }}
                    />
                    <div className="reviewCardTopText">
                      <div style={{ fontWeight: "600" }}>{review?.realname}</div>
                      <Rating
                        fillColorArray={gradientColors}
                        size="16"
                        initialValue={review?.rating}
                        readonly="true"
                      />
                    </div>
                  </div>
                  <div className="reviewCardText">{review?.review ? review?.review : <i>No review.</i>}</div>
                </div>
              ))}
            </div>

            <div className="gradient-container">
              <div className="fade-effect-right"></div>
            </div>

            <div onClick={() => scroll(350)} className="scroll-button">
              <img src="/scroll-right.png" height="48px" />
            </div>
          </div>
          }
         <div style={{marginTop:"20px", marginLeft:"auto", display:"flex", gap:"10px"}}>
          <button className="applyButton gradientBackground" onClick={() => navigator.clipboard.writeText("https://dainis.web.app/worker/" + workerProfile?.worker).then(() => {
                    // Optional: Display a message or perform an action to indicate copying was successful.
                    SweetAlert('Copied to clipboard');
                  }, (err) => {
                    // Optional: Handle any errors.
                    SweetAlert("Could not copy text.");
                  })}>Share profile</button>
          {chainaccount == workerProfile?.worker  ? null : <button className="applyButton gradientBackground"  onClick={() => setOpen(true)}>Leave a review</button>}
          </div>        
          </div>
      </div>
    </div>
  );
}

export default WorkerProfile;
